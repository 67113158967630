import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import pimg1 from '../../../images/clients/client_logo_1.webp'
import pimg2 from '../../../images/clients/client_logo_2.webp'
import pimg3 from '../../../images/clients/client_logo_3.webp'
import pimg4 from '../../../images/clients/client_logo_4.webp'
import pimg5 from '../../../images/clients/client_logo_5.webp'
import pimg6 from '../../../images/clients/client_logo_6.webp'
import pimg7 from '../../../images/clients/client_logo_7.webp'
import HP from '../../../images/clients/icons8-hp-100.png'
import DELL from '../../../images/clients/icons8-dell-100.png'
import ASUS from '../../../images/clients/icons8-asus-100.png'
import LENOVO from '../../../images/clients/icons8-lenovo-100.png'
import APPLE from '../../../images/clients/icons8-apple-100.png'
import MI from '../../../images/clients/icons8-xiaomi-100.png'
import AMD from '../../../images/clients/icons8-amd-100.png'
import INTEL from '../../../images/clients/icons8-intel-corporation-an-american-multinational-corporation-and-technology-company-100.png'
import TCS from '../../../images/clients/TCS.png'
import Accenture from '../../../images/clients/Accenture-Logo.png'
import BENQ from '../../../images/clients/benq-logo-1-2048x587.png'
import COGNIZENT from '../../../images/clients/conigent.jpg'
import HCL from '../../../images/clients/hcl_technologies_logo.svg'
import IBM from '../../../images/clients/IBM-Logo-1967-1972.png'
import infosys from '../../../images/clients/infosys.png'
import Oracle from '../../../images/clients/Oracle-Emblema.png'
import WIPRO from '../../../images/clients/wipro.png'


const partners = [
    {
        pImg: pimg1,
    },
    {
        pImg: HP,
    },
    {
        pImg: ASUS,
    },
    {
        pImg: LENOVO,
    },
    {
        pImg: DELL,
    },
    {
        pImg: APPLE,
    },
    {
        pImg: MI,
    },
    {
        pImg: AMD,
    },
    {
        pImg: INTEL,
    },
    {
        pImg: pimg2,
    },
    {
        pImg: pimg3,
    },
    {
        pImg: TCS,
    },
    {
        pImg: Accenture,
    },
    {
        pImg: BENQ,
    },
    {
        pImg: COGNIZENT,
    },
    {
        pImg: HCL,
    },
    {
        pImg: pimg4,
    },
    {
        pImg: IBM,
    },
    {
        pImg: infosys,
    },
    {
        pImg: Oracle,
    },
    {
        pImg: pimg5,
    },
    {
        pImg: WIPRO,
    },
    {
        pImg: pimg6,
    },
    {
        pImg: pimg7,
    },
]

var settings = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 7,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    cssEase: "linear",
    arrows: false,

    responsive: [
        {
            breakpoint: 1025,
            settings: {
                slidesToShow: 7,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 450,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 350,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};



const FeaturePartners = (props) => {

    return (
        <div className="feature_partners_section">
            <div className="container position-relative">
                <div className="title_text text-white">
                    Invecent Partner's
                </div>
                <div className="client_logo_carousel">
                    <Slider {...settings}>
                        {partners.map((partner, pitem) => (
                            <div className="client_logo_item" key={pitem}>
                                <img src={partner.pImg} alt="Invecent - Client Logo" />
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    );
}

export default FeaturePartners;