import { Helmet } from 'react-helmet';

/**
 * Custom hook for managing meta tags and SEO
 * @param {Object} props - Meta tag properties
 * @param {string} props.title - Page title
 * @param {string} props.description - Page description
 * @param {string} props.keywords - SEO keywords
 * @param {string} props.imageUrl - Social media image URL
 * @param {string} props.canonicalUrl - Canonical URL for the page
 * @param {Object} props.additionalTags - Any additional meta tags
 * @returns {JSX.Element} Helmet component with meta tags
 */
const useMetaTags = ({
    title = "Invecent Private Limited - Software Solutions & Services",
    description = "Invecent Private Limited is a leading software company offering innovative solutions for businesses. Explore our services and products.",
    keywords = "software company, software solutions, business services, technology, innovation, digital transformation, IT consulting",
    imageUrl = "%PUBLIC_URL%/Invecentlogohead.png",
    Url = "https://www.invecent.com",
    additionalTags = {}
}) => {
    // Base meta tags that will be used across all pages
    const baseMetaTags = {
        title,
        description,
        keywords,
        imageUrl,
        Url
    };

    // Merge base tags with any additional tags provided
    const metaTags = { ...baseMetaTags, ...additionalTags };

    return (
        <Helmet>
            {/* Primary Meta Tags */}
            <title>{metaTags.title}</title>
            <meta name="title" content={metaTags.title} />
            <meta name="description" content={metaTags.description} />
            <meta name="keywords" content={metaTags.keywords} />
            <link rel="canonical" href={metaTags.Url} />
            
            {/* Open Graph / Facebook */}
            <meta property="og:type" content="website" />
            <meta property="og:url" content={metaTags.Url} />
            <meta property="og:title" content={metaTags.title} />
            <meta property="og:description" content={metaTags.description} />
            <meta property="og:image" content={metaTags.imageUrl} />
            <meta property="og:site_name" content="Invecent Private Limited" />
            
            {/* Twitter */}
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content={metaTags.canonicalUrl} />
            <meta property="twitter:title" content={metaTags.title} />
            <meta property="twitter:description" content={metaTags.description} />
            <meta property="twitter:image" content={metaTags.imageUrl} />

            {/* Additional SEO Meta Tags */}
            <meta name="robots" content="index, follow" />
            <meta name="language" content="English" />
            <meta name="revisit-after" content="7 days" />
            <meta name="author" content="Invecent Private Limited" />
            
            {/* Google Specific */}
            <meta name="google" content="notranslate" />
            <meta name="google-site-verification" content="your-google-verification-code" />

            {/* Google Analytics and Tag Manager are already in index.html */}
            
            {/* Render any additional custom meta tags */}
            {Object.entries(additionalTags).map(([name, content]) => (
                <meta key={name} name={name} content={content} />
            ))}
        </Helmet>
    );
};

export default useMetaTags;
