import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import tImg1 from '../../images/clients/infosys.png'

const testimonial = [
    {
      id: '01',
      tImg: tImg1,
      Title: 'Amazing Services',
      Des: "As a startup, we needed a technology partner who could help us bring our vision to life.",
      Name: 'Ravi Kumar',
      sub: 'Marketing Manager',
      rating: 5
    },
    {
      id: '02',
      tImg: tImg1,
      Title: 'We Are Satisfied',
      Des: "We've been partnering with Invecent for several years, and they have consistently delivered.",
      Name: 'Priya Sharma',
      sub: 'Product Manager',
      rating: 4
    },
    {
      id: '03',
      tImg: tImg1,
      Title: 'Fast Respond',
      Des: "Working with Invecent was a seamless experience from start to finish.",
      Name: 'Arjun Mehta',
      sub: 'Director of Operations',
      rating: 4
    },
    {
      id: '04',
      tImg: tImg1,
      Title: 'Amazing Services',
      Des: "The team at Invecent surpassed our expectations. Their management and technical expertise were exceptional.",
      Name: 'Kiran Patel',
      sub: 'Marketing Specialist',
      rating: 5
    },
    {
      id: '05',
      tImg: tImg1,
      Title: 'Transformative Partnership',
      Des: "As a startup, we needed a technology partner who could help us bring our vision to life.",
      Name: 'Ananya Roy',
      sub: 'Project Coordinator',
      rating: 4
    },
    {
      id: '06',
      tImg: tImg1,
      Title: 'Consistent Excellence',
      Des: "We've been partnering with Invecent for several years, and they have consistently delivered exceptional results.",
      Name: 'Vikram Singh',
      sub: 'Software Engineer',
      rating: 5
    },
    {
      id: '08',
      tImg: tImg1,
      Title: 'Seamless Collaboration',
      Des: "Working with Invecent was a transformative experience.",
      Name: 'Neha Gupta',
      sub: 'Business Analyst',
      rating: 4
    },
    {
      id: '09',
      tImg: tImg1,
      Title: 'Technical Mastery',
      Des: "The team at Invecent surpassed our expectations with their technical prowess and innovative problem-solving.",
      Name: 'Amit Khanna',
      sub: 'Finance Manager',
      rating: 4
    },
    {
      id: '10',
      tImg: tImg1,
      Title: 'Strategic Partnership',
      Des: "Invecent has been instrumental in our digital transformation journey.",
      Name: 'Ishita Desai',
      sub: 'Technology Consultant',
      rating: 5
    }
  ];
  

const Testimonial = () => {
const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
        {
            breakpoint: 1025,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                arrows: false
            }
        },
        {
            breakpoint: 769,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                arrows: false
            }
        },
        {
            breakpoint: 450,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false
            }
        },
        {
            breakpoint: 350,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false
            }
        }
    ]
};

return (
    <section className="review_section section_space bg-light" >
        <div className="container">
            <div className="heading_block text-center">
                <div className="heading_focus_text">
                    <h4><h4 className="badge bg-secondary text-white">Client</h4>
                    Testimonials</h4>
                </div>
                <h2 className="heading_text mb-0">
                    What clients say
                </h2>
            </div>

            <Slider {...settings}>
                {testimonial.map((testimonial, tsm) => (
                    <div key={tsm} className="px-5">
                        <div 
                            className="review_block bg-white p-4 rounded shadow-sm" 
                            style={{
                                height: '400px', 
                                display: 'flex', 
                                flexDirection: 'column', 
                                justifyContent: 'space-between'
                            }}
                        >
                            <div>
                                <h3 className="review_title mb-3">"{testimonial.Title}"</h3>
                                <p className="review_comment text-muted mb-4">
                                    {testimonial.Des}
                                </p>
                            </div>
                            <div className="review_admin d-flex align-items-center">
                                <div className="review_admin_image me-3">
                                    <img 
                                        src={testimonial.tImg} 
                                        alt={testimonial.Name} 
                                        className="rounded-circle" 
                                        style={{width: '50px', height: '50px', objectFit: 'cover'}} 
                                    />
                                </div>
                                <div className="review_admin_info">
                                    <h4 className="review_admin_name mb-1">{testimonial.Name}</h4>
                                    <span className="review_admin_designation text-muted">
                                        {testimonial.sub}
                                    </span>
                                    <div>
                                    {[...Array(testimonial.rating)].map((_, index) => (
                                        <span 
                                        key={index} 
                                        style={{
                                            color: index < testimonial.rating ? '#ffc107' : '#e4e5e9',
                                            fontSize: '1.5rem',
                                            marginRight: '3px'
                                        }}
                                        >
                                        ★
                                        </span>
                                    ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    </section>
);
}

export default Testimonial;