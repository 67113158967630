import React, { useEffect, useRef } from "react";
import { motion, useAnimation, useInView } from "framer-motion";

const CardEffect = ({
  children,
  width = "100%",
  variants = {
    hidden: { opacity: 0, y: 75 },
    peek: { opacity: 1, y: 0 },
  },
  initial = "hidden",
  animate = "peek",
  transition = { duration: 1, delay: 0.69 },
}) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start(animate); // Use the animate prop
    }
  }, [isInView, mainControls, animate]);

  return (
    <div ref={ref} style={{ position: "relative", width }}>
      <motion.div
        variants={variants}
        initial={initial}
        animate={mainControls}
        transition={transition}
      >
        {children}
      </motion.div>
    </div>
  );
};

export default CardEffect;
