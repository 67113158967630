import React from 'react'
import { Link } from 'react-router-dom'
import footerbg from '../../images/backgrounds/footerbg.jpg'
import Services from '../../api/service'
import blackbg from '../../images/backgrounds/blackbg.jpg'


const ClickHandler = () => {
    window.scrollTo(10, 0);
}


const Footer = (props) => {
    return (
        <footer className="site_footer footer_layout_1">
            <div className="content_box" 
                           style={{ 
                            backgroundImage: `url(${blackbg})`, 
                            backgroundSize: 'cover', 
                            backgroundRepeat: 'no-repeat', 
                            backgroundPosition: 'center',
                            overflow: 'hidden'
                        }}>
                <div className="container-fluid position-relative px-6 z-1" 
                                    style={{
                                        background: 'linear-gradient(169deg, #00000099, rgba(41, 128, 185, 0.6))',
                                        top: 0,
                                        left: 0,
                                        zIndex: 1
                                    }}>                    
                    <div className="footer_main_content px-1 px-md-5">
                        <div className="row justify-content-lg-between">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="footer_widget pe-md-3">
                                    <h2 className="footer_info_title text-center underline">About us</h2>
                                    <p className="text-white">
                                    At Invecent, we are dedicated to redefining possibilities in the digital world. Guided by our core values of Innovation, Inspiration, and Integration, we craft transformative solutions.
                                    Invecent's multidisciplinary team combines creativity, technical expertise, and strategic thinking to deliver innovative solutions tailored to your business needs.
                                    </p>

                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="footer_widget">
                                    <h3 className="footer_info_title">Solutions</h3>
                                    <ul className="icon_list unordered_list_block">
                                        {Services.slice(6, 12).map((service, srv) => (
                                            <li key={srv}>
                                                {service.title ?
                                                    <Link onClick={ClickHandler} to={`/service-single/${service.slug}`}>
                                                        <span className="icon_list_text">
                                                            {service.thumb1}
                                                        </span>
                                                    </Link>
                                                    : ''}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="footer_widget">
                                    <h3 className="footer_info_title">Technologies</h3>
                                    <ul className="icon_list unordered_list_block">
                                        <li>
                                            <Link onClick={ClickHandler} to="/Technologies">
                                                <span className="icon_list_text">
                                                    Web Technologies
                                                </span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link onClick={ClickHandler} to="/Technologies">
                                                <span className="icon_list_text">
                                                    Backend Technologies
                                                </span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link onClick={ClickHandler} to="/Technologies">
                                                <span className="icon_list_text">
                                                Cloud Technologies
                                                </span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link onClick={ClickHandler} to="/Technologies">
                                                <span className="icon_list_text">
                                                    Devops Technologies
                                                </span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link onClick={ClickHandler} to="/Technologies">
                                                <span className="icon_list_text">
                                                    Mobile Technologies
                                                </span>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6 col-sm-6">
                                <div className="footer_widget">
                                    <h3 className="footer_info_title quick_links">Quick Links</h3>
                                    <ul className="icon_list unordered_list_block">
                                        <li>
                                            <Link onClick={ClickHandler} to="/about">
                                                <span className="icon_list_text">
                                                    About 
                                                </span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link onClick={ClickHandler} to="/collaborations/Universities">
                                                <span className="icon_list_text">
                                                    Collaboration
                                                </span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link onClick={ClickHandler} to="/careers">
                                                <span className="icon_list_text">
                                                    Careers
                                                </span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link onClick={ClickHandler} to="/CSR">
                                                <span className="icon_list_text">
                                                    CSR
                                                </span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link onClick={ClickHandler} to="/contact">
                                                <span className="icon_list_text">
                                                Contact
                                                </span>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer_bottom">
                <div className="container d-md-flex align-items-md-center justify-content-md-between">
                    <p className="copyright_text m-0">
                        Copyright © 2024 Invecent, All rights reserved.
                    </p>
                    <p className="copyright_text m-0">
                        Developed by <Link onClick={ClickHandler} to="/">Invecent</Link>
                    </p>
                </div>
            </div>
        </footer>
    )
}

export default Footer;