import React, { Fragment, useState } from 'react';
import ModalVideo from 'react-modal-video'
import Header2 from '../../components/Header2/Header2';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer';
import TechnologySection from '../../components/software-company-components/TechnologySection/TechnologySection';
import icon from '../../images/icons/icon_check_3.svg'
import ContactFormNew from '../../components/contactFormNew/contactFormNew';
import SEOWrapper from '../../components/SEOWrapper/SEOWrapper';

const features = ['Cloud Computing', 'Artificial Intelligence', 'Cybersecurity', 'Data Analytics', 'DevOps', 'Internet of Things', 'Machine Learning', 'Blockchain', 'Virtual Reality', '5G Networks'];

const TechnologiesPage = () => {
    const metaData = {
        title: "Technologies & Tools | Invecent Private Limited",
        description: "Explore our technology stack and tools at Invecent Private Limited. We leverage cutting-edge technologies including cloud computing, AI/ML, blockchain, and more to deliver innovative solutions.",
        keywords: "technology stack, development tools, cloud computing, artificial intelligence, machine learning, blockchain, web development, mobile development, DevOps",
        additionalTags: {
            'og:type': 'website',
            'article:section': 'Technologies',
            'og:locale': 'en_US'
        }
    };

    const [isOpen, setOpen] = useState(false)

    return (
        <SEOWrapper metaData={metaData}>
            <Fragment>
                <Header2 />
                <main className="page_content service-single-page">
                    <PageTitle pageTitle={'Technologies'} pagesub={'Overview'} pageTop={'Technology'}/>
                    <section className="service_details_section bg-light">
                        <div className="container">
                        <TechnologySection />
                            <h2 className="details_item_title">Invecents Technologies Stack</h2>
                            <p>
                            As a software company, we excel in developing innovative solutions that cater to the distinct needs of our clients. From startups to multinational corporations, we recognize the pivotal role of a well-designed software infrastructure in fostering efficiency, security, and scalability. Our team of seasoned experts collaborates closely with clients.
                            </p>
                            <p>
                                We adopt a comprehensive approach to software development, considering aspects such as performance requirements, security protocols, scalability, and future growth potential. By harnessing industry best practices and cutting-edge technologies,
                            </p>
                            <h3 className="details_item_info_title">Services Outcome</h3>
                            <p className="mb-4">
                                Here are six key points that can be associated with a digital Transformation gallery case global Digital Systems Engineer Services leader helping Fortune 500 companies on their innovation agenda:
                            </p>
                            <div className="row mb-4">
                                <div className="col-lg-6">
                                    <ul className="icon_list unordered_list_block">
                                        {features.slice(0,5).map((feature, featureitem) => (
                                        <li key={featureitem}>
                                            <span className="icon_list_icon">
                                                <img src={icon} alt="Check SVG Icon"/>
                                            </span>
                                            <span className="icon_list_text">
                                                {feature}
                                            </span>
                                        </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="col-lg-6">
                                    <ul className="icon_list unordered_list_block">
                                    {features.slice(6,15).map((feature, featureitem) => (
                                        <li key={featureitem}>
                                            <span className="icon_list_icon">
                                                <img src={icon} alt="Check SVG Icon"/>
                                            </span>
                                            <span className="icon_list_text">
                                                {feature}
                                            </span>
                                        </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            {/* <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                    <div className="details_item_image m-0">
                                        <img src={ServiceDetails.subImg1} alt="Service Details"/>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                    <div className="details_item_image m-0">
                                        <img src={srImg3} alt="Service Details"/>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </section>
                    <ContactFormNew />
                </main>
                <Footer />
                <Scrollbar />
                <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="7e90gBu4pas" onClose={() => setOpen(false)} />
            </Fragment>
        </SEOWrapper>
    )
};
export default TechnologiesPage;