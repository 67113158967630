import React, { useState } from 'react'
import SimpleReactValidator from 'simple-react-validator';
import { Parallax } from 'react-parallax';
import process from '../../images/about/ProcessBig.webm'
import emailjs from '@emailjs/browser';
import ArifEffect from '../../main-component/Animations/ArifEffect';
import blackbg from '../../images/backgrounds/blackbg.webp'

const ContactFormNew = (props) => {
    const [submitStatus, setSubmitStatus] = useState({ success: false, error: null });
    const [loadingState, setLoadingState] = useState({ show: false, message: '', type: 'loading' });

    const customVariants1 = {
        hidden: { opacity: 0, y: 100 },
        peek: { opacity: 1, y: 0 },
    };

    const [forms, setForms] = useState({
        fullName: '',
        email: '',
        company: '',
        phone: '',
        message: ''
    });

    const [validator] = useState(new SimpleReactValidator({
        className: 'errorMessage'
    }));

    const changeHandler = (e) => {
        const { name, value } = e.target;
        setForms(prevForms => ({
            ...prevForms, 
            [name]: value
        }));
    };

    const submitHandler = async (e) => {
        e.preventDefault();

        if (!validator.allValid()) {
            validator.showMessages();
            return;
        }

        try {
            const response = await emailjs.send(
                'service_fkik5si',  // Your EmailJS Service ID
                'template_b5pozam',  // Your EmailJS Template ID
                {
                    from_name: forms.fullName,
                    from_email: forms.email,
                    phone: forms.phone,
                    company: forms.company,
                    message: forms.message
                },
                'GjPapmR56EZN6vO_4'  // Your EmailJS Public Key
            );

            if (response.status === 200) {
                setSubmitStatus({ success: true, error: null });
                // Reset form
                setForms({
                    fullName: '',
                    email: '',
                    company: '',
                    phone: '',
                    message: ''
                });
                // Optional: Show success message
                alert('Message sent successfully!');
                submitStatus({ success: true, error: null });
                loadingState({ show: true, message: 'Message sent successfully!', type: 'success' });
            }
        }  catch (error) {
            // Email failed, fallback to WhatsApp message
            setSubmitStatus({ success: false, error: 'Failed to send application. Please try again.' });
            setLoadingState({ 
                show: true, 
                message: 'There was an error submitting your application. Please send it via WhatsApp.',
                type: 'error' 
            });
    
            // Generate a WhatsApp link
            const whatsappMessage = encodeURIComponent(
                `Application Submission Details:\n\n` +
                `Name: ${forms.fullName}\n` +
                `Email: ${forms.email}\n` +
                `Phone: ${forms.phone}\n` +
                `Additional Notes: Email submission failed. Sending via WhatsApp.`
            );
    
            // Directly open WhatsApp web
            window.open(`https://wa.me/919177393512?text=${whatsappMessage}`, '_blank');
            
            // Update submission status
            setSubmitStatus({ success: false, error: 'Email submission failed. Redirecting to WhatsApp.' });
            setLoadingState({ 
                show: true, 
                message: 'Email submission failed. Redirecting to WhatsApp...',
                type: 'error' 
            });

            // Optional: Add a fallback timeout
            setTimeout(() => {
                setLoadingState({ show: false, message: '', type: 'loading' });
            }, 3000);
        }
    };
    
    return (
        <Parallax
            blur={0}
            bgImage={blackbg}
            strength={200}
            className="calltoaction_section"
        >
            <div className="container">
                <div className="row justify-content-lg-end">
                <div className='col-lg-6 col-md-11 image_wrap position-relative'>
                <a href='https://www.invecent.com' aria-label='Invecent process'><video autoPlay loop muted style={{ width: '86%', marginLeft: '8%', borderRadius: '16px' }}>
                    <source src={process} type="video/webm" />
                  </video>
                  </a>
                </div>
                    <div className="col-lg-6 col-md-11">
                    <ArifEffect variants={customVariants1}  transition = {{duration: 1, delay: 0.69 }} >
                        <div className="instant_contact_form shadow-none">
                            <div className="small_title text-white">
                                <i className="fa-solid fa-envelope-open-text text-white"> </i>
                                 Let's Connect!
                            </div>
                            <h3 className="form_title text-white">
                                Send us a message, and we'll promptly discuss your project with you.
                            </h3>
                            <form className="xb-item--form contact-from" onSubmit={(e) => submitHandler(e)}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="input_title" htmlFor="input_name">
                                                <i className="fa-regular fa-user"></i>
                                            </label>
                                            <input
                                                value={forms.fullName}
                                                type="text"
                                                name="fullName"
                                                className="form-control"
                                                onBlur={(e) => changeHandler(e)}
                                                onChange={(e) => changeHandler(e)}
                                                placeholder="Your Name" />
                                            {validator.message('name', forms.fullName, 'required|alpha_space')}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="input_title" htmlFor="input_email">
                                                <i className="fa-regular fa-envelope"></i>
                                            </label>
                                            <input
                                                value={forms.email}
                                                type="email"
                                                name="email"
                                                className="form-control"
                                                onBlur={(e) => changeHandler(e)}
                                                onChange={(e) => changeHandler(e)}
                                                placeholder="Your Enter" />
                                            {validator.message('email', forms.email, 'required|email')}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="input_title" htmlFor="input_phone">
                                                <i className="fa-regular fa-phone-volume"></i>
                                            </label>
                                            <input
                                                value={forms.phone}
                                                type="phone"
                                                name="phone"
                                                className="form-control"
                                                onBlur={(e) => changeHandler(e)}
                                                onChange={(e) => changeHandler(e)}
                                                placeholder="Your Phone No." />
                                            {validator.message('phone', forms.phone, 'required|phone')}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="input_title" htmlFor="input_company">
                                                <i className="fa-regular fa-globe"></i>
                                            </label>
                                            <input
                                                value={forms.company}
                                                type="company"
                                                name="company"
                                                className="form-control"
                                                onBlur={(e) => changeHandler(e)}
                                                onChange={(e) => changeHandler(e)}
                                                placeholder="Your Company Name" />
                                            {validator.message('company', forms.company, 'required')}
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="input_title" htmlFor="input_textarea">
                                                <i className="fa-regular fa-comments"></i>
                                            </label>
                                            <textarea
                                                onBlur={(e) => changeHandler(e)}
                                                onChange={(e) => changeHandler(e)}
                                                value={forms.message}
                                                type="text"
                                                name="message"
                                                className="form-control"
                                                placeholder="How can we help you?">
                                            </textarea>
                                            {validator.message('message', forms.message, 'required')}
                                        </div>
                                        <button type="submit" className="btn btn-primary">
                                            <span className="btn_label" data-text="Send Request">Send Request</span>
                                            <span className="btn_icon">
                                                <i className="fa-solid fa-arrow-up-right"></i>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </ArifEffect>
                    </div>
                </div>
            </div>
        </Parallax>
    )
}

export default ContactFormNew;