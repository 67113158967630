import React, { useState } from 'react';
// import Bg2 from '../../../images/shapes/shape_bg_1.webp'
import Bg3 from '../../../images/shapes/shape_title_under_line.svg'
// import aIcon4 from '../../../images/icons/icon_global.svg'
// import aIcon5 from '../../../images/shapes/shape_line.webp'
import shape1 from '../../../images/shapes/shape_space_2.svg'
import ArifEffect from '../../../main-component/Animations/ArifEffect'

const About = (props) => {
  const [activeTab, setActiveTab] = useState('mission');

  const customVariants1 = {
    hidden: { opacity: 0, x: -100 },
    peek: { opacity: 1, x: 0 },
  };

  const companyDetails = {
    mission: {
      icon: 'fa-rocket',
      title: 'Invecent Mission',
      description: 'To empower businesses through innovative software solutions that streamline operations, foster growth, and drive digital transformation..',
      color: '#0dcaf0'
      },
    vision: {
      icon: 'fa-eye',
      title: 'Invecent Vision',
      description: 'To be a global leader in technological innovation, consistently pushing the boundaries of what\'s possible we aim to create transformative solutions.',
      color: '#33FF57'
    },
    values: {
      icon: 'fa-handshake',
      title: 'Invecent Values',
      description: 'Innovation, Integrity, Collaboration, and Excellence. We believe in creating solutions that are not just technologically advanced, but also ethically.',
      color: '#F4A261'
    },
    benefits: {
      icon: 'fa-thumbs-up',
      title: 'Invecent Benefits',
      description: 'Cutting-edge technology, ethical practices, collaborative partnerships, and exceptional results. We deliver value-driven solutions tailored to your success.',
      color: '#f8f9fa'
    }    
  };

  return (
    <section className="about_and_case_section section_space section_decoration" style={{ backgroundColor: '#343a40ed' }}>
      <div className="container">
        <div className="row align-items-center justify-content-lg-between">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <ArifEffect variants={customVariants1} transition={{duration: 1, delay: 0.69}}>
              <div className="about_content">
                <div className="heading_block mb-4 text-white">
                  <div className="heading_focus_text text-center about_us has_underline d-inline-flex " style={{ backgroundImage: `url(${Bg3})`, color: 'white' }}>
                    <h2 className='text-white'>About Us</h2>
                  </div>
                  <h2 className="heading_text mt-3 mb-3">
                  Invecent leads the way in groundbreaking innovations that go beyond limits.
                  </h2>
                  <p className="heading_description mb-4 text-light">
                    We are a dynamic technology partner dedicated to transforming business challenges into innovative digital solutions. Our multidisciplinary team combines creativity, technical expertise, and strategic thinking.
                    At Invecent, we are dedicated to redefining possibilities in the digital world. Guided by our core values of Innovation, Inspiration, and Integration, we craft transformative solutions.
                  </p>
                </div>
              </div>
            </ArifEffect>
          </div>
          
          <div className="col-lg-12 col-md-12 col-sm-12">
            <ArifEffect>
              
                {/* Tabs for Mission, Vision, Values */}
                <div className="company_details_tabs">
                  <div className="row g-4">
                    {Object.keys(companyDetails).map((key) => (
                      <div key={key} className="col-xl-3 col-md-6 col-sm-12">
                        <div 
                          className={`card h-100 p-4 shadow-sm border-0 border-primary`}
                          style={{
                            transition: 'all 0.3s ease',
                            transform: activeTab === key ? 'translateY(-10px)' : 'translateY(0)',
                            backgroundColor: companyDetails[key].color
                          }}
                          onClick={() => setActiveTab(key)}
                        >
                          <div className="d-flex align-items-center mb-3">
                            <i 
                              className={`fas ${companyDetails[key].icon} text-primary me-3 fa-2x`}
                              style={{
                                backgroundColor: 'rgba(52, 152, 219, 0.1)', 
                                padding: '10px', 
                                borderRadius: '50%'
                              }}
                            ></i>
                            <h4 className="title mb-0">{companyDetails[key].title}</h4>
                          </div>
                          <p className="card-text text-muted">
                            {companyDetails[key].description}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
            </ArifEffect>
          </div>
        </div>
      </div>
      <div className="decoration_item shape_image_1">
        <img src={shape1} alt="Invecent Shape" />
      </div>
    </section>
  )
}

export default About;