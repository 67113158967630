import React, { Fragment } from 'react';
import SEOWrapper from '../../components/SEOWrapper/SEOWrapper';
import Header2 from '../../components/Header2/Header2';
import Hero2 from '../../components/hero2/hero2';
import FeaturePartners from '../../components/software-company-components/FeaturePartners';
import ServiceSection from '../../components/software-company-components/ServiceSection/ServiceSection';
import About from '../../components/software-company-components/about/about';
import ProcessTechnology from '../../components/software-company-components/ProcessTechnology/ProcessTechnology';
import FaqSection from '../../components/software-company-components/FaqSection/FaqSection';
import Scrollbar from '../../components/scrollbar/scrollbar';
// import BlogSection from '../../components/software-company-components/BlogSection/BlogSection';
import Footer from '../../components/footer/Footer';
import PolicySection from '../../components/PolicySection/PolicySection';
// import Casestudy from '../../components/software-company-components/casestudy/casestudy.js';
import ContactFormNew from '../../components/contactFormNew/contactFormNew.js';
import Testimonial from '../../components/Testimonial/Testimonial.js';
import Loactions from '../Locations/Locations.js';

const HomePage2 = () => {

    const metaData = {
        title: "Invecent Private Limited - Home | Software Solutions & Services",
        description: "Welcome to Invecent Private Limited. We provide cutting-edge software solutions and IT services for businesses. Explore our innovative technology solutions.",
        keywords: "software company, IT services, digital solutions, technology consulting, software development, Invecent, business solutions",
        additionalTags: {
            'og:type': 'website',
            'og:locale': 'en_US'
        }
    };

    return (
        <SEOWrapper metaData={metaData}>
            <Fragment>
                <div>
                    <Header2 />
                    <main className="page_content">
                        <Hero2 />
                        <FeaturePartners />
                        <PolicySection />
                        <ServiceSection />
                        <About />
                        <ProcessTechnology />
                        <ContactFormNew /> 
                        {/* <ContactSection /> */}
                        {/* <Casestudy /> */}
                        <Testimonial />
                        <FaqSection />
                        {/* <BlogSection /> */}
                    </main>
                    <Loactions />
                    <Footer />
                    <Scrollbar />
                <script id="ze-snippet" src="https://static.zdassets.com/ekr/snippet.js?key=92c81846-4dfe-4443-aede-f927bfe07cbf"> </script>
                </div>
            </Fragment>
        </SEOWrapper>
    )
};
export default HomePage2;