import WebDevMain from '../images/services/WebDevMain.webp'
import AppDevMain from '../images/services/AppDevMain.webp'
import EnterpriseMain from '../images/services/entripriseMain.webp'
import ErpSub1 from '../images/services/ErpSub1.webp'
import UIUXMain from '../images/services/UIUXMain.webp'
import UIUXSub2 from '../images/services/UIUXSub2.webp'
import DigitalMarketing from '../images/services/digitalmarketing/Types of digital marketing.webp'
import DigitalMarketingSub2 from '../images/services/DigitalMarketingSub2.webp'
import DataSceinceGenAiMain from '../images/services/GenAiMain.webp'
import DataScienceSub2 from '../images/services/DataScienceSub1.webp'

import SoftwareManagmentSub1 from '../images/services/SoftwareManagmentSub1.webp'
import NetworkManagmentMain from '../images/services/NetworkManagmentMain.webp'
import NetworkManagmentSub2 from '../images/services/networkManagmentSub2.webp'
import amc from '../images/services/amc.webp'
import amcsub1 from '../images/services/amcSub1.jpg'
import amcsub2 from '../images/services/amcsub2.png'
import amcsub3 from '../images/services/amcsub3.png'
import SoftwareTestingMain from '../images/services/SoftwareTestingMain.webp'
import SoftwareTestingSub2 from '../images/services/SoftwareTestingSub2.webp'
import CyberSecurity from '../images/services/CyberSecurity.webp'
import CyberSecuritySub2 from '../images/services/CyberSecuritySub2.webp'
import HRMSMain from '../images/services/HRMSMain.webp'
import HRMSSub2 from '../images/services/HRMSSub2.jpg'


import CloudMigrationSub1 from '../images/services/CLoudMigrationSub1.webp'
import CloudMigrationSub2 from '../images/services/CLoudMigrationSub2.webp'
import ServersStorageSub1 from '../images/services/ServersStoragesSub1_1.webp'
import ServersStorageSub2 from '../images/services/ServersStoragesSub2.webp'
import DesktopSupportMain from '../images/services/DesktopSupportMain.webp'
import DesktopSupportSub1 from '../images/services/desktopsupportSub1.webp'
import ITStaffingSub1 from '../images/services/ITStaffingsub1.webp'
import ITStaffingSub2 from '../images/services/ITStaffingsub2.webp'
import DataAnalyticsMain from '../images/services/DataAnalyticsMain.webp'
import SystemIntegrationSub1 from '../images/services/SystemIntegrationSub1.webp'
import SystemIntegrationSub2 from '../images/services/SystemIntegrationSub2.webp'


import servicebg from '../images/services/servicebg.webp'

import Web from '../images/about/web-development.png'
import App from '../images/about/App-development.png'
import Erp from '../images/about/erp-system.png'
import UIUX from '../images/about/ui-ux-design.png'
import Digital from '../images/about/digital-marketing.png'
import dataScience from '../images/about/data-science.png'

import SoftwareDevelopment from '../images/services/software managment/44.webp'
import agile from '../images/services/software managment/39.webp'
import resources from '../images/services/software managment/38.webp'
import cicd from '../images/services/software managment/41.webp'
import performance from '../images/services/software managment/43.webp'
import custom from '../images/services/software managment/45.webp'
import agile2 from '../images/services/software managment/42.webp'

import optimization from '../images/services/networking/Untitled design (2).webp'
import security from '../images/services/networking/Untitled design (3).webp'
import cloud from '../images/services/networking/Untitled design (1).webp'
import recovery from '../images/services/networking/Untitled design (4).webp'
import scalability from '../images/services/networking/Untitled design (5).webp'
import monitoring from '../images/services/networking/Untitled design.webp'

import compliance from '../images/services/cybersecurity/Untitled design (1).webp'
import cybersecurity from '../images/services/cybersecurity/Untitled design (2).webp'
import cyberMonitor from '../images/services/cybersecurity/Untitled design (3).webp'
import encryption from '../images/services/cybersecurity/Untitled design (4).webp'
import alltimesecurity from '../images/services/cybersecurity/Untitled design (5).webp'
import threat2 from '../images/services/cybersecurity/Untitled design (6).webp'

import functionTesting from '../images/services/testing/33.webp'
import audit from '../images/services/testing/34.webp'
import automated from '../images/services/testing/35.webp'
import performanceTesting from '../images/services/testing/36.webp'
import securityAudit from '../images/services/testing/37.webp'

import systemmaintenance from '../images/services/amc/15.webp'
import Updates from '../images/services/amc/Untitled design (1).webp'
import repairs from '../images/services/amc/Untitled design.webp'

import hrmsScalability from '../images/services/hrms/2.webp'
import hrmsPerformance from '../images/services/hrms/Employee Performence.webp'
import hrmsAutomation from '../images/services/hrms/HRProcess.webp'
import Payroll from '../images/services/hrms/Untitled design.webp'
import hrmsData from '../images/services/hrms/Data Driven desion.webp'

import fullstack from '../images/services/webdevelopment/FullStack.webp'
import API from '../images/services/webdevelopment/api.webp'
import backEndvsfront from '../images/services/webdevelopment/frontvsbackend.webp'
import ecommerse from '../images/services/webdevelopment/Ecommerse.webp'
import fullstack2 from '../images/services/webdevelopment/fullStack2.webp'
import webportal from '../images/services/webdevelopment/Webportals.webp'
import native from '../images/services/app development/Untitled design (1).webp'
import maintenance from '../images/services/app development/Untitled design (2).webp'
import uiux from '../images/services/app development/Untitled design (3).webp'
import crossplatform from '../images/services/app development/Untitled design (5).webp'
import enterprise from '../images/services/app development/Untitled design (6).webp'
import cross from '../images/services/app development/Untitled design.webp'

import erpabout from '../images/services/erp/1.webp'
import erpintegration from '../images/services/erp/2.webp'
import erpmigration from '../images/services/erp/3.webp'  
import customerp from '../images/services/erp/4.webp'
import erpcloud from '../images/services/erp/5.webp'

import user from '../images/services/uiux/Untitled.webp'
import wireframing from '../images/services/uiux/Untitled design (3).webp'
import interactive from '../images/services/uiux/Untitled design (5).webp'
import usability from '../images/services/uiux/Untitled design (6).webp'
import mobile from '../images/services/uiux/Untitled design.webp'


import seo from '../images/services/digitalmarketing/47.webp'
import social from '../images/services/digitalmarketing/46.webp'
import email from '../images/services/digitalmarketing/Untitled design (2).webp'    
import content from '../images/services/digitalmarketing/Untitled design (3).webp'
import ppc from '../images/services/digitalmarketing/Untitled design (1).webp'

import integration from '../images/services/datascience/Untitled design (1).webp'
import aisolution from '../images/services/datascience/Untitled design (2).webp'
import predictive from '../images/services/datascience/Untitled design (3).webp'
import analytics from '../images/services/datascience/Untitled design (4).webp'
import ml from '../images/services/datascience/Untitled design.webp'

import contacttohire from '../images/services/staffing/17.webp'
import hr from '../images/services/staffing/18.webp'
import staffingprocess from '../images/services/staffing/19.webp'
import Recruitment from '../images/services/staffing/20.webp'
import acquisition from '../images/services/staffing/21.webp'

import cloudstrategy from '../images/services/cloud/2.webp'
import cloudmigration from '../images/services/cloud/3.webp'
import cloudsecurity from '../images/services/cloud/4.webp'
import stepscloud from '../images/services/cloud/5.webp'
import cloudprocess from '../images/services/cloud/6.webp'

import serverperfromance from '../images/services/servers/7.webp'
import servermaintenance from '../images/services/servers/8.webp'
import serversecurity from '../images/services/servers/9.webp'
import serverstorage from '../images/services/servers/11.webp'
import servermigration from '../images/services/servers/10.webp'

import remoteacess from '../images/services/desktop support/12.webp'
import incedentmanagment from '../images/services/desktop support/13.webp'
import issueresolve from '../images/services/desktop support/14.webp'
import itsupporttypes from '../images/services/desktop support/15.webp'
import sucessmodel from '../images/services/desktop support/16.webp'

import businessintelligence from '../images/services/data analytics/22.webp'
import datawarehouse from '../images/services/data analytics/23.webp'
import bigdata from '../images/services/data analytics/24.webp'
import datavisualization from '../images/services/data analytics/25.webp'
import analysistypes from '../images/services/data analytics/26.webp'
import predictiveanalysis from '../images/services/data analytics/27.webp'

import systemintegration from '../images/services/system integration/29.webp'
import compatability from '../images/services/system integration/28.webp'
import legacysystem from '../images/services/system integration/30.webp'
import connectivity from '../images/services/system integration/31.webp'
import apiintegration from '../images/services/system integration/32.webp'

const Services = [
    {
        Id: '1',
        sImg: SoftwareDevelopment,
        bgImg: servicebg,
        subImg1: SoftwareManagmentSub1,
        title: 'Invecent Private Limited Company Streamlines Development with Experts in India',
        slug: 'Software-Management',
        thumb1: 'Strategy',
        thumb2: 'Consultation',
        col: 'col-lg-6',
        description: 'Streamline your development process, optimize software performance, and ensure effective resource management with our Software Management services. We help businesses maximize their software investments and drive continuous improvement.',
        description2: 'Empower your enterprise with cutting-edge software management strategies tailored to optimize resources and drive efficiency. By enhancing development processes and monitoring performance, our solutions boost productivity and support business scalability, giving your company a competitive edge in today’s dynamic marketplace.',
        description3: "Invecent Private Limited Company’s expert team excels in streamlining software development with innovative and efficient solutions. Our professionals leverage cutting-edge methodologies and tools to enhance resource optimization and software performance. Need guidance on the latest software strategies or tools? Reach out for timely, result-oriented services tailored to your business needs!",
        features: ['Agile Methodology', 'Resource Optimization', 'Continuous Integration', 'Software Performance Monitoring', 'Custom Software Solutions', 'Agile CICD process'],
        featuresImage: [agile, resources, cicd, performance, custom, agile2],
    },  
    {
        Id: '2',
        sImg: NetworkManagmentMain,
        bgImg: servicebg,
        subImg1: NetworkManagmentSub2,
        title: 'Invecent Private Limited Company Optimizes Connectivity Network Management in India',
        slug: 'Network-Infrastructure-Management',
        thumb1: 'Infrastructure',
        thumb2: 'Management',
        col: 'col-lg-6',
        description: 'Ensure high availability, security, and performance with our Network and Infrastructure Management services. Our team helps businesses build, manage, and optimize network infrastructure for seamless connectivity.',
        description2: 'Strengthen your business connectivity with robust network infrastructure solutions designed for scalability and security. Our services help minimize downtime, enhance operational efficiency, and safeguard your systems, ensuring your enterprise stays ahead in a fast-paced digital environment.',
        description3: "Invecent Private Limited Company offers advanced network infrastructure management solutions that ensure seamless connectivity and robust security. Our team combines expertise with the latest technologies to minimize downtime and enhance scalability. Not sure how to manage cloud integration or disaster recovery? Contact us for reliable and swift support tailored to your goals!",
        features: ['Network Optimization', 'Security Monitoring', 'Cloud Integration', 'Disaster Recovery Planning', 'Scalability Solutions', '24/7 Monitoring'],
        featuresImage: [optimization, security, cloud, recovery, scalability, monitoring],
    },
    {
        Id: '3',
        sImg: CyberSecurity,
        bgImg: servicebg,
        subImg1: CyberSecuritySub2,
        subImg2: CyberSecuritySub2,
        title: 'Invecent Private Limited Company has Comprehensive CyberSecurity Protection in India',
        slug: 'Cyber-Security',
        thumb1: 'Security',
        thumb2: 'Data Management',
        col: 'col-lg-6',
        description: 'Protect your business from evolving threats with our comprehensive CyberSecurity solutions. We offer server management, data storage, and protection services, ensuring the security, reliability, and scalability of your operations.',
        description2: 'Safeguard your digital assets with cutting-edge cybersecurity solutions that prevent data breaches and ensure compliance. Our proactive threat detection and advanced security measures protect your company’s reputation and data integrity, helping you build customer trust and sustain business growth.',
        description3: "Invecent Private Limited Company provides end-to-end cybersecurity solutions to shield your business from evolving threats. Our team uses advanced threat detection and proactive security measures to ensure data integrity and compliance. Need assistance with server security or data encryption? Trust us for high-quality and prompt services designed to protect your business!",
        features: ['Advanced Threat Detection', 'Server Security Management', 'Data Encryption', 'Security Compliance', '24/7 Security Monitoring','Cybersecurity Monitoring'],
        featuresImage: [compliance, cybersecurity, encryption, alltimesecurity, cyberMonitor, threat2],
    },
    {
        Id: '4',
        sImg: SoftwareTestingMain,
        bgImg: servicebg,
        subImg1: SoftwareTestingSub2,
        title: 'Invecent Private Limited Company Elevates Software Reliability with Testing in India',
        slug: 'Testing-Audit-Functions',
        thumb1: 'Audit',
        thumb2: 'Software Testing',
        col: 'col-lg-6',
        description: 'Ensure software quality and compliance with our Testing and Audit services. We provide thorough assessments to ensure your software is secure, functional, and meets industry standards.',
        description2: 'Elevate your software quality with rigorous testing and auditing that ensures peak performance and compliance with industry standards. Our detailed assessments identify vulnerabilities and improve functionality, giving your business a strong foundation for scalability and reliability.',
        description3: "Invecent Private Limited Company specializes in elevating software reliability with meticulous testing and auditing solutions. Our experts use advanced tools to identify vulnerabilities and ensure compliance. Worried about functional testing or security audits? Let us deliver efficient and comprehensive assessments for peak software performance!",
        features: ['Automated Testing', 'Compliance Audits', 'Performance Testing', 'Security Audits', 'Functional Testing'],
        featuresImage: [automated, audit, performanceTesting, securityAudit, functionTesting],
    },
    {
        Id: '5',
        sImg: amcsub1,
        bgImg: amc,
        subImg1: amcsub2,
        title: 'Invecent Private Limited Company has Seamless IT Operations with IT AMC in India',
        slug: 'Computer-AMC-Service',
        thumb1: 'Computer Management',
        thumb2: 'AMC Service',
        col: 'col-lg-6',
        description: 'Ensure your computer systems are always up-to-date and running smoothly with our Computer AMC (Annual Maintenance Contract) services. Our team provides comprehensive support, maintenance, and repair services to minimize downtime and optimize system performance.',
        description2: 'Reduce downtime and enhance operational efficiency with our Computer AMC services that keep your IT systems optimized and secure. From routine maintenance to hardware upgrades, our solutions ensure your technology supports your growth ambitions seamlessly.',
        description3: "Invecent Private Limited Company ensures seamless IT operations with our Computer AMC services. Our professionals are skilled in routine maintenance, upgrades, and repairs to optimize system performance. Concerned about technical support or software updates? Count on us for proactive and timely solutions tailored to your business needs!",
        features: ['System Maintenance', 'Repair Services', 'Software Updates', 'Hardware Upgrades', 'Technical Support'],
        featuresImage: [systemmaintenance, repairs, Updates, repairs, amcsub3],
    },
    {
        Id: '6',
        sImg: HRMSMain,
        bgImg: servicebg,
        subImg1: HRMSSub2,
        title: 'Invecent Private Limited Company Optimizes HR Process with HRMS Assessments in India',
        slug: 'HRMS-Assessments',
        thumb1: 'HR Management',
        thumb2: 'Assessment',
        col: 'col-lg-6',
        description: 'Optimize your Human Resource Management Systems (HRMS) to align with your business goals. Our assessments help improve HR processes, increase efficiency, and ensure smooth integration with your enterprise systems.',
        description2: 'Streamline your HR operations with our expert HRMS assessments, designed to improve process efficiency and drive workforce engagement. With customized automation and insightful analytics, we enable your business to achieve strategic alignment and operational excellence.',
        description3: "Invecent Private Limited Company enhances HR operations through tailored HRMS assessments. Our solutions drive efficiency and foster workforce engagement with automation and insightful analytics. Need support optimizing your HR processes or integrating advanced tools? Partner with us for strategic and impactful results!",
        features: ['HR Process Optimization', 'Employee Performance Evaluation', 'Automation Solutions', 'Data-Driven Insights', 'Scalability & Flexibility'],
        featuresImage: [hrmsScalability, hrmsPerformance, hrmsAutomation, hrmsData, Payroll],
    },
    {
        Id: '7',
        sImg: fullstack2,
        sicon: Web,
        bgImg: servicebg,
        subImg1: WebDevMain,
        title: 'Invecent Private Limited Company exellence in Innovative Web Development in India',
        slug: 'Web-Application-Development',
        thumb1: 'Web Development',
        thumb2: 'Frontend',
        col: 'col-lg-4',
        description: 'Create robust, scalable, and secure web applications with our full-stack web development services. We deliver custom solutions that enhance user experience and meet your business needs.',
        description2: 'Drive online growth with high-performing web solutions designed to captivate your audience and achieve business objectives. From responsive design to seamless e-commerce functionality, our web development services enhance user engagement and digital presence.',
        description3: "Invecent Private Limited Company empowers businesses with innovative web development solutions that deliver high-performing, responsive, and scalable web applications. Need help with full-stack development, API integration, or e-commerce? Partner with us for cutting-edge web solutions that prioritize timely delivery and optimal results!",
        features: ['Full-Stack Web Development', 'Responsive Web Design', 'E-Commerce Solutions', 'API Integration', 'Web Portal Development'],
        featuresImage: [fullstack, backEndvsfront, ecommerse, API, webportal],
    },
    {
        Id: '8',
        sImg: AppDevMain,
        sicon: App,
        bgImg: servicebg,
        subImg1: crossplatform,
        title: 'Invecent Private Limited Company provides High Innovative Mobile Apps in India',
        slug: 'Mobile-Application-Development',
        thumb1: 'Mobile Development',
        thumb2: 'App Development',
        col: 'col-lg-4',
        description: 'Develop innovative and user-friendly mobile applications for iOS and Android platforms. Our mobile development services create high-performing apps tailored to your business objectives.',
        description2: 'Expand your reach with user-centric mobile applications that enhance customer engagement and deliver a superior experience. Our development expertise ensures seamless functionality, boosting your brand’s presence across mobile platforms.',
        description3: "Invecent Private Limited Company creates intuitive and dynamic mobile applications tailored to meet your business needs. Our experts deliver exceptional user experiences across platforms, ensuring seamless functionality and customer engagement. Looking to enhance your app strategy with the latest tools and designs? Reach out for quality and efficient solutions!",
        features: ['Native App Development', 'Cross-Platform Development', 'UI/UX Design', 'Mobile App Maintenance', 'Enterprise Mobile Solutions'],
        featuresImage: [native, cross, uiux, maintenance, enterprise],
    },
    {
        Id: '9',
        sImg: EnterpriseMain,
        sicon: Erp,
        bgImg: servicebg,
        subImg1: ErpSub1,
        title: 'Invecent Private Limited Company offers Custom Enterprise Applications in India',
        slug: 'Enterprise-Application',
        thumb1: 'Enterprise Application',
        thumb2: 'Enterprise Solutions',
        col: 'col-lg-3',
        description: 'Develop and integrate scalable enterprise applications that support your business growth. Our enterprise solutions optimize operations and integrate seamlessly with your existing infrastructure.',
        description2: 'Streamline your business processes with tailored enterprise applications that drive efficiency and innovation. Our solutions enable seamless system integration, enhancing your organizational agility and fostering sustainable growth.',
        description3: "Invecent Private Limited Company delivers custom enterprise applications that streamline operations and drive innovation. Our tailored solutions enhance system integration and data management, empowering businesses to scale effectively. Need ERP solutions or custom applications? Let our experts deliver impactful results!",
        features: ['ERP Solutions', 'System Integration', 'Data Migration', 'Custom Business Applications', 'Cloud-based Enterprise Solutions'],
        featuresImage: [erpabout, erpintegration, erpmigration, customerp, erpcloud],

    },
    {
        Id: '10',
        sImg: UIUXMain,
        sicon: UIUX,
        bgImg: servicebg,
        subImg1: UIUXSub2,
        title: 'Invecent Private Limited Company has Engaging UI/UX Designs for Everyone in India',
        slug: 'UI-UX-Designing',
        thumb1: 'UI & UX Designing',
        thumb2: 'UX Design',
        col: 'col-lg-3',
        description: 'Enhance user engagement and interface design with our expert UI/UX design services. We create intuitive and visually appealing user experiences for both web and mobile applications.',
        description2: 'Boost user satisfaction and retention with intuitive UI/UX designs that prioritize usability and aesthetics. Our designs are crafted to align with business goals, creating seamless experiences that resonate with your audience.',
        description3: "Invecent Private Limited Company specializes in creating captivating and user-friendly UI/UX designs that resonate with audiences. From wireframing to usability testing, our designs ensure enhanced user satisfaction and retention. Seeking to elevate your digital interfaces? Trust us for creative and effective solutions!",
        features: ['User-Centered Design', 'Mobile-First Approach', 'Wireframing and Prototyping', 'Interactive UI Elements', 'Usability Testing'],
        featuresImage: [user, interactive, wireframing, mobile, usability],

    },
    {
        Id: '11',
        sImg: DigitalMarketing,
        sicon: Digital,
        bgImg: servicebg,
        subImg1: DigitalMarketingSub2,
        title: 'Invecent Private Limited Company is the Result Oriented digital marketing agency in India',
        slug: 'Digital-Marketing',
        thumb1: 'Digital Marketing',
        thumb2: 'Digital Marketing',
        col: 'col-lg-3',
        description3: 'Invecent Private Limited Company is having Digital Marketing team of experts, growth innovative and creative minds! Let’s unlock the rapid growth of your business with the help of proficient and creative professionals. We are hardworking yet finicky about timely delivery and results. Don’t know about the algorithms of Facebook, Instagram, Twitter, Linked or YouTube? Worried about growth digital marketing strategies with advanced AI tools? Reach us for quality and Quick Services!',
        description: 'Boost your online presence and drive targeted traffic with our comprehensive Digital Marketing services. From SEO to social media, we help businesses succeed in the digital space.',
        description2: 'Transform your digital presence with marketing strategies that deliver measurable results. Our expertise in SEO, PPC, and social media ensures maximum ROI, helping your business thrive in a competitive online marketplace.',
        features: ['SEO Optimization', 'PPC Advertising', 'Social Media Marketing', 'Content Marketing', 'Email Campaigns'],
        featuresImage: [seo, ppc, social, content, email],
    },
    {
        Id: '12',
        sImg: DataSceinceGenAiMain,
        sicon: dataScience,
        bgImg: servicebg,
        subImg1: DataScienceSub2,
        title: 'Invecent Private Limited Company uses Data Science & GenAI to Empower Growth in India',
        slug: 'DataScience-GenAI',
        thumb1: 'DataScience & GenAI',
        thumb2: 'Artificial Intelligence',
        col: 'col-lg-3',
        description: 'Leverage the power of Data Science and GenAI to drive smarter business decisions. We offer AI-driven solutions to optimize ERP, data management, and business processes.',
        description2: 'Unlock the potential of your data with advanced analytics and AI solutions. Our expertise helps you make data-driven decisions, optimize business processes, and gain a competitive edge in an AI-driven economy.',
        description3: "Invecent Private Limited Company unlocks growth with advanced Data Science and GenAI solutions. From predictive modeling to AI-driven analytics, we empower smarter decision-making. Want to leverage data as a strategic asset? Our experts deliver reliable and transformative results!",
        features: ['Machine Learning', 'Data Analytics', 'AI Solutions', 'Predictive Modeling', 'Data Integration'],
        featuresImage: [ml, analytics, aisolution, predictive, integration],

    },
    {
        Id: '13',
        sImg: ITStaffingSub1,
        bgImg: servicebg,
        subImg1: ITStaffingSub2,
        title: 'Invecent Private Limited Company provides IT Staffing - Result-Oriented Solutions in India',
        slug: 'IT-Staffing',
        thumb1: 'IT Professionals',
        thumb2: 'Recruitment',
        col: 'col-lg-6',
        description: 'Find top-tier IT professionals to meet your business needs with our IT Staffing services. We connect you with skilled talent to support your digital transformation.',
        description2: 'Build a winning IT team with our staffing solutions tailored to your unique needs. By providing access to top tech talent, we enable businesses to scale operations and achieve technology-driven success.',
        description3: "Invecent Private Limited Company connects businesses with top-tier IT talent, ensuring the right fit for your technical needs. From flexible staffing to contract-to-hire services, we prioritize efficiency and success. Need skilled IT professionals? Trust us to optimize your team with precision and expertise!",
        features: ['Talent Sourcing', 'IT Recruitment', 'Flexible Staffing Solutions', 'Contract-to-Hire Services', 'On-Demand Talent'],
        featuresImage: [acquisition, Recruitment, staffingprocess, contacttohire, hr],

    },
    {
        Id: '14',
        sImg: CloudMigrationSub2,
        bgImg: servicebg,
        subImg1: CloudMigrationSub1,
        title: 'Invecent Private Limited Company provides Migration to Cloud - Seamless Transition in India',
        slug: 'Migration-to-Cloud',
        thumb1: 'Cloud',
        thumb2: 'Migration',
        col: 'col-lg-6',
        description: 'Migrate your infrastructure to the cloud with minimal disruption, maximizing scalability and flexibility. Our cloud migration services ensure a smooth transition for your business.',
        description2: 'Future-proof your business with seamless cloud migration services. Our strategies ensure minimal downtime while unlocking scalability, flexibility, and cost efficiency for sustainable growth.',
        description3: "Invecent Private Limited Company ensures seamless cloud migration with tailored strategies that minimize downtime and maximize scalability. Our expert team transforms your infrastructure for optimal efficiency. Planning your cloud journey? Partner with us for reliable and innovative solutions!",
        features: ['Cloud Strategy', 'Cloud Migration Planning', 'Data Migration', 'Cloud Infrastructure Optimization', 'Post-Migration Support'],
        featuresImage: [cloudstrategy, cloudmigration, cloudprocess, stepscloud, cloudsecurity],

    },
    {
        Id: '15',
        sImg: ServersStorageSub2,
        bgImg: servicebg,
        subImg1: ServersStorageSub1,
        title: 'Invecent Private Limited Company provides Servers & Storages - Optimized Solutions in India',
        slug: 'Servers-Storages',
        thumb1: 'Servers',
        thumb2: 'Maintenance',
        col: 'col-lg-6',
        description: 'Maximize your server and storage infrastructure with our expert maintenance and support services. Our team ensures your systems are always running at peak performance.',
        description2: 'Enhance business efficiency with reliable server and storage solutions tailored to your needs. Our maintenance services keep your systems optimized, ensuring uninterrupted operations and peak performance.',
        description3: "Invecent Private Limited Company optimizes server and storage solutions for peak performance and reliability. From maintenance to performance tuning, our services ensure uninterrupted operations. Want to strengthen your IT infrastructure? Trust us for expert support and tailored solutions!",
        features: ['Server Maintenance', 'Storage Optimization', 'System Monitoring', 'Security Audits', 'Performance Tuning'],
        featuresImage: [servermaintenance, serverperfromance, servermigration, serversecurity, serverstorage],
    },
    {
        Id: '16',
        sImg: DesktopSupportMain,
        bgImg: servicebg,
        subImg1: DesktopSupportSub1,
        title: 'Invecent Private Limited Company provides Desktop Support - Reliable Services in India',
        slug: 'Desktop-Support',
        thumb1: 'Helpdesk',
        thumb2: 'Support',
        col: 'col-lg-6',
        description: 'Our service desk support ensures your IT infrastructure is always up and running, providing fast and efficient issue resolution for your business operations.',
        description2: 'Deliver seamless IT operations with our efficient desktop support services. We resolve issues promptly, ensuring business continuity and enhancing user satisfaction across your organization.',
        description3: "Invecent Private Limited Company delivers reliable desktop support services, ensuring prompt issue resolution and seamless operations. Our expertise enhances user satisfaction and IT efficiency. Looking for responsive and effective IT support? We’ve got you covered!",
        features: ['IT Support Desk', 'Issue Resolution', 'Incident Management', 'User Access Support', 'Remote Assistance', 'On-Demand Support levels'],
        featuresImage: [sucessmodel, issueresolve, incedentmanagment, user, remoteacess, itsupporttypes],
    },
    {
        Id: '17',
        sImg: DataAnalyticsMain,
        bgImg: servicebg,
        subImg1: analysistypes,
        title: 'Invecent Private Limited Company exels in Data Analytics - Insight-Driven Expertise in India',
        slug: 'Data-Analytics',
        thumb1: 'Analytics',
        thumb2: 'Big Data',
        col: 'col-lg-6',
        description: 'Leverage data analytics to drive business insights and make data-driven decisions. Our data analytics services help you gain a competitive edge and optimize business performance.',
        description2: 'Transform data into actionable insights with advanced analytics solutions. Our services empower you to make strategic decisions, optimize performance, and stay ahead in an increasingly data-driven world.',
        description3: "Invecent Private Limited Company empowers businesses with data analytics solutions that deliver actionable insights and strategic advantages. From predictive analytics to custom reporting, we optimize your decision-making. Ready to harness the power of your data? Let’s make it happen together!",
        features: ['Data Visualization', 'Predictive Analytics', 'Big Data Solutions', 'Business Intelligence', 'Custom Reporting', 'Data Warehousing'],
        featuresImage: [datavisualization, predictiveanalysis, bigdata, businessintelligence, analysistypes, datawarehouse],
    },
    {
        Id: '18',
        sImg: SystemIntegrationSub2,
        bgImg: servicebg,
        subImg1: SystemIntegrationSub1,
        title: 'Invecent Private Limited Company provides System Integration - Unified Solutions in India',
        slug: 'System-Integration',
        thumb1: 'Integration',
        thumb2: 'Technology',
        col: 'col-lg-6',
        description: 'Integrate diverse systems and technologies to create a cohesive IT environment. Our system integration services help you connect applications, data, and devices for seamless operations.',
        description2: 'Streamline your IT ecosystem with seamless system integration services. By connecting applications and data, we help you create an efficient, unified infrastructure that drives productivity and innovation.',
        description3: "Invecent Private Limited Company excels in system integration services, creating unified IT ecosystems that boost productivity and innovation. From API integration to legacy system compatibility, we connect your technology seamlessly. Need streamlined IT operations? Trust us to deliver exceptional results!",
        features: ['API Integration', 'Cloud Integration', 'Legacy System Integration', 'Data Synchronization', 'Technology Compatibility'],
        featuresImage: [apiintegration, connectivity, legacysystem, systemintegration, compatability],
    }    
];



export default Services;