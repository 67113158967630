
import React, { Fragment } from 'react';
// import Bg from '../../../images/shapes/shape_title_under_line.svg'
// import shape1 from '../../../images/shapes/shape_line_2.svg'
// import shape2 from '../../../images/shapes/shape_line_3.svg'
// import shape3 from '../../../images/shapes/shape_line_4.svg'
// import shape4 from '../../../images/shapes/shape_space_3.svg'
// import aboutpic from '../../../images/about/web-sub1.webp'
import ArifEffect from '../../../main-component/Animations/ArifEffect';
import WorkingProcess from '../../../images/about/WorkingProcess.webm';

import TechnologySection from '../TechnologySection/TechnologySection';

const FaqSection = (props) => {

  const customVariants1 = {
    hidden: { opacity: 0, x: -100 },
    peek: { opacity: 1, x: 0 },
  };
  const customVariants2 = {
    hidden: { opacity: 0, x: 100 },
    peek: { opacity: 1, x: 0 },
  };

    // const [open, setOpen] = useState('1');
    // const toggle = (id) => {
    //     if (open === id) {
    //         setOpen();
    //     } else {
    //         setOpen(id);
    //     }
    // };

    return (
        <Fragment>
        <section className="empowering_success_section section_space bg-light">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="heading_block text-center">
                  <h2 className="heading_text mb-0">
                    Empowering Success Through Strategic Consulting Since 2018
                  </h2>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-6">
              <ArifEffect variants={customVariants1}  transition = {{duration: 1, delay: 0.69 }}>
                <div className="image_wrap position-relative">
                  <a href='https://www.invecent.com' aria-label='Invecent Working Process'><video autoPlay loop muted style={{ width: '69%', marginLeft: '15%', borderRadius: '16px' }}>
                    <source src={WorkingProcess} type="video/webm" />
                  </video>
                  </a>
                </div>
              </ArifEffect>
              </div>
              <div className="col-lg-6">
              <ArifEffect variants={customVariants2}  transition = {{duration: 1, delay: 0.69 }}>
                <ul className="empowering_services unordered_list_block ps-lg-5">
                  <li>
                    <div className="iconbox_block layout_icon_left">
                      <div className="iconbox_icon" style={{ backgroundColor: 'white' }}>
                        <img src="https://html.xpressbuddy.com/techco/assets/images/icons/icon_bulb.svg" alt="Bulb SVG Icon" />
                      </div>
                      <div className="iconbox_content">
                        <h3 className="iconbox_title">Tailored Solutions</h3>
                        <p className="mb-0">
                          Our consulting services are customized to suit the unique needs and  goals of each client, ensuring precise alignment with their business  objectives.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="iconbox_block layout_icon_left">
                      <div className="iconbox_icon">
                        <img src="https://html.xpressbuddy.com/techco/assets/images/icons/icon_user_rating.svg" alt="User Rating SVG Icon" />
                      </div>
                      <div className="iconbox_content">
                        <h3 className="iconbox_title">Collaborative Approach</h3>
                        <p className="mb-0">
                          We believe in fostering strong partnerships with our clients, working  closely with them to understand their challenges, identify  opportunities.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="iconbox_block layout_icon_left">
                      <div className="iconbox_icon">
                        <img src="https://html.xpressbuddy.com/techco/assets/images/icons/icon_hand_shake.svg" alt="Hand Shake SVG Icon" />
                      </div>
                      <div className="iconbox_content">
                        <h3 className="iconbox_title">Expert Team Members</h3>
                        <p className="mb-0">
                          We believe in fostering strong partnerships with our clients, working  closely with them to understand their challenges, identify  opportunities.
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
                </ArifEffect>
              </div>
            </div>
            <div className="row ">
              <TechnologySection />
            </div>
          </div>
        </section>
        </Fragment>
    )
};
export default FaqSection;
