import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import emailjs from '@emailjs/browser';
import SEOWrapper from '../SEOWrapper/SEOWrapper';
import Header2 from '../Header2/Header2';
import Footer from '../footer/Footer';
import Scrollbar from '../scrollbar/scrollbar';
import PageTitle from '../pagetitle/PageTitle';
import ArifEffect from '../../main-component/Animations/ArifEffect';

const InternshipPage = () => {
    const [submitStatus, setSubmitStatus] = useState({ success: false, error: null });
    const [loadingState, setLoadingState] = useState({ show: false, message: '', type: 'loading' });

    const internshipPrograms = [
        {
            title: "Software Development",
            duration: "3-6 months",
            type: "Full-time",
            stipend: "Competitive",
            skills: ["Java", "Python", "React", "Node.js"]
        },
        {
            title: "Digital Marketing",
            duration: "3 months",
            type: "Part-time available",
            stipend: "Performance-based",
            skills: ["Social Media", "Content Writing", "SEO", "Analytics"]
        },
        {
            title: "Business Development",
            duration: "4 months",
            type: "Full-time",
            stipend: "Performance-based",
            skills: ["Sales", "Communication", "Market Research", "CRM"]
        },
        {
            title: "UI/UX Design",
            duration: "3 months",
            type: "Flexible",
            stipend: "Competitive",
            skills: ["Figma", "Adobe XD", "Wireframing", "Prototyping"]
        }
    ];

    const validationSchema = Yup.object().shape({
        fullName: Yup.string()
            .required('Full name is required')
            .min(2, 'Name must be at least 2 characters'),
        email: Yup.string()
            .email('Invalid email address')
            .required('Email is required'),
        phone: Yup.string()
            .matches(/^[0-9]{10}$/, 'Phone number must be 10 digits')
            .required('Phone number is required'),
        program: Yup.string()
            .required('Program selection is required'),
        college: Yup.string()
            .required('College/University name is required'),
        course: Yup.string()
            .required('Course details are required'),
        graduation: Yup.string()
            .required('Expected graduation date is required'),
        skills: Yup.string()
            .required('Skills are required'),
        portfolio: Yup.string()
            .url('Must be a valid URL')
            .required('Portfolio/GitHub link is required'),
        availability: Yup.string()
            .required('Availability information is required'),
        whyJoin: Yup.string()
            .min(50, 'Please provide more details about why you want to join')
            .required('This field is required')
    });

    const initialValues = {
        fullName: '',
        email: '',
        phone: '',
        program: '',
        college: '',
        course: '',
        graduation: '',
        skills: '',
        portfolio: '',
        availability: '',
        whyJoin: ''
    };

    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        try {
            const response = await emailjs.send(
                'service_fkik5si',
                'template_b5pozam',
                {
                    from_name: values.fullName,
                    from_email: values.email,
                    phone: values.phone,
                    program: values.program,
                    college: values.college,
                    course: values.course,
                    graduation: values.graduation,
                    skills: values.skills,
                    portfolio: values.portfolio,
                    availability: values.availability,
                    why_join: values.whyJoin
                },
                'GjPapmR56EZN6vO_4'
            );

            if (response.status === 200) {
                setSubmitStatus({ success: true, error: null });
                resetForm();
            }
        }  catch (error) {
            // Email failed, fallback to WhatsApp message
            setSubmitStatus({ success: false, error: 'Failed to send application. Please try again.' });
            setLoadingState({ 
                show: true, 
                message: 'There was an error submitting your application. Please send it via WhatsApp.',
                type: 'error' 
            });
    
            // Generate a WhatsApp link
            const whatsappMessage = encodeURIComponent(
                `Application Submission Details:\n\n` +
                `Name: ${values.fullName}\n` +
                `Email: ${values.email}\n` +
                `Phone: ${values.phone}\n` +
                `Portfolio: ${values.portfolio}\n` +
                `LinkedIn: ${values.linkedin}\n` +
                `Additional Notes: Email submission failed. Sending via WhatsApp.`
            );
    
            // Directly open WhatsApp web
            window.open(`https://wa.me/919177393512?text=${whatsappMessage}`, '_blank');
            
            // Update submission status
            setSubmitStatus({ success: false, error: 'Email submission failed. Redirecting to WhatsApp.' });
            setLoadingState({ 
                show: true, 
                message: 'Email submission failed. Redirecting to WhatsApp...',
                type: 'error' 
            });

            // Optional: Add a fallback timeout
            setTimeout(() => {
                setLoadingState({ show: false, message: '', type: 'loading' });
            }, 3000);
        }
        setSubmitting(false);
    };

    const metaData = {
        title: "Internship Opportunities | Invecent Private Limited",
        description: "Launch your career with Invecent's internship programs. Gain hands-on experience in software development, digital marketing, business development, and more.",
        keywords: "internship, student internship, tech internship, marketing internship, business internship, career start",
        additionalTags: {
            'og:type': 'website',
            'article:section': 'Careers',
            'og:locale': 'en_US'
        }
    };

    return (
        <SEOWrapper metaData={metaData}>
            <Header2 />
            <main className="page_content">

            <PageTitle pageTitle={'Internship Programs'} pagesub={'Launch Your Career'}/>
                
            <div className="internship-section section_space">
                <div className="container">
                    {/* Programs Section */}
                    <div className="row mb-5">
                        <div className="col-12">
                            <div className="section_heading text-center mb-5">
                                <h2 className="heading_text">Available Internship Programs</h2>
                                <p className="heading_description">
                                    Start your career journey with hands-on experience at Invecent
                                </p>
                            </div>
                        </div>
                        
                        {internshipPrograms.map((program, index) => (
                            <div className="col-lg-3 col-md-6 mb-4" key={index}>
                            <ArifEffect>
                                <div className="internship-card">
                                    <div className="card-body">
                                        <h3 className="program-title">{program.title}</h3>
                                        <div className="program-details">
                                            <p><i className="fas fa-clock"></i> {program.duration}</p>
                                            <p><i className="fas fa-user-clock"></i> {program.type}</p>
                                            <p><i className="fas fa-wallet"></i> {program.stipend}</p>
                                        </div>
                                        <div className="skills-section">
                                            <h4>Key Skills:</h4>
                                            <div className="skills-list">
                                                {program.skills.map((skill, idx) => (
                                                    <span key={idx} className="skill-tag">{skill}</span>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ArifEffect>
                            </div>
                        ))}
                    </div>

                    {/* Benefits Section */}
                    <div className="row mb-5">
                        <div className="col-12">
                            <div className="benefits-section">
                                <h2 className="text-center mb-4">Why Intern at Invecent?</h2>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="benefit-item">
                                            <i className="fas fa-laptop-code"></i>
                                            <h3>Real-World Experience</h3>
                                            <p>Work on actual projects and gain practical industry experience</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="benefit-item">
                                            <i className="fas fa-users"></i>
                                            <h3>Mentorship</h3>
                                            <p>Learn from industry experts and get personal guidance</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="benefit-item">
                                            <i className="fas fa-certificate"></i>
                                            <h3>Career Growth</h3>
                                            <p>Opportunity for full-time roles upon successful completion</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Application Form */}
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="application-section">
                                <h2 className="text-center mb-4">Apply Now</h2>
                                <div className="application-form-wrapper">
                                    <Formik
                                        initialValues={initialValues}
                                        validationSchema={validationSchema}
                                        onSubmit={handleSubmit}
                                    >
                                        {({ errors, touched, isSubmitting }) => (
                                            <Form className="internship-form">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <Field
                                                                type="text"
                                                                name="fullName"
                                                                placeholder="Full Name *"
                                                                className={`form-control ${errors.fullName && touched.fullName ? 'is-invalid' : ''}`}
                                                            />
                                                            {errors.fullName && touched.fullName && (
                                                                <div className="invalid-feedback">{errors.fullName}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <Field
                                                                type="email"
                                                                name="email"
                                                                placeholder="Email Address *"
                                                                className={`form-control ${errors.email && touched.email ? 'is-invalid' : ''}`}
                                                            />
                                                            {errors.email && touched.email && (
                                                                <div className="invalid-feedback">{errors.email}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <Field
                                                                type="tel"
                                                                name="phone"
                                                                placeholder="Phone Number *"
                                                                className={`form-control ${errors.phone && touched.phone ? 'is-invalid' : ''}`}
                                                            />
                                                            {errors.phone && touched.phone && (
                                                                <div className="invalid-feedback">{errors.phone}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <Field
                                                                as="select"
                                                                name="program"
                                                                className={`form-control ${errors.program && touched.program ? 'is-invalid' : ''}`}
                                                            >
                                                                <option value="">Select Program *</option>
                                                                {internshipPrograms.map((program, index) => (
                                                                    <option key={index} value={program.title}>
                                                                        {program.title}
                                                                    </option>
                                                                ))}
                                                            </Field>
                                                            {errors.program && touched.program && (
                                                                <div className="invalid-feedback">{errors.program}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <Field
                                                                type="text"
                                                                name="college"
                                                                placeholder="College/University *"
                                                                className={`form-control ${errors.college && touched.college ? 'is-invalid' : ''}`}
                                                            />
                                                            {errors.college && touched.college && (
                                                                <div className="invalid-feedback">{errors.college}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <Field
                                                                type="text"
                                                                name="course"
                                                                placeholder="Course/Major *"
                                                                className={`form-control ${errors.course && touched.course ? 'is-invalid' : ''}`}
                                                            />
                                                            {errors.course && touched.course && (
                                                                <div className="invalid-feedback">{errors.course}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <Field
                                                                type="text"
                                                                name="graduation"
                                                                placeholder="Expected Graduation Date *"
                                                                className={`form-control ${errors.graduation && touched.graduation ? 'is-invalid' : ''}`}
                                                            />
                                                            {errors.graduation && touched.graduation && (
                                                                <div className="invalid-feedback">{errors.graduation}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <Field
                                                                type="text"
                                                                name="skills"
                                                                placeholder="Relevant Skills *"
                                                                className={`form-control ${errors.skills && touched.skills ? 'is-invalid' : ''}`}
                                                            />
                                                            {errors.skills && touched.skills && (
                                                                <div className="invalid-feedback">{errors.skills}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <Field
                                                                type="url"
                                                                name="portfolio"
                                                                placeholder="Portfolio/GitHub Link *"
                                                                className={`form-control ${errors.portfolio && touched.portfolio ? 'is-invalid' : ''}`}
                                                            />
                                                            {errors.portfolio && touched.portfolio && (
                                                                <div className="invalid-feedback">{errors.portfolio}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <Field
                                                                type="text"
                                                                name="availability"
                                                                placeholder="Available Start Date & Duration *"
                                                                className={`form-control ${errors.availability && touched.availability ? 'is-invalid' : ''}`}
                                                            />
                                                            {errors.availability && touched.availability && (
                                                                <div className="invalid-feedback">{errors.availability}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <Field
                                                                as="textarea"
                                                                name="whyJoin"
                                                                placeholder="Why do you want to join our internship program? *"
                                                                className={`form-control ${errors.whyJoin && touched.whyJoin ? 'is-invalid' : ''}`}
                                                                rows="4"
                                                            />
                                                            {errors.whyJoin && touched.whyJoin && (
                                                                <div className="invalid-feedback">{errors.whyJoin}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-12 text-center">
                                                        <button
                                                            type="submit"
                                                            className="btn btn-primary submit-btn"
                                                            disabled={isSubmitting}
                                                        >
                                                            {isSubmitting ? 'Submitting...' : 'Submit Application'}
                                                        </button>
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>

                                    {submitStatus.success && (
                                        <div className="alert alert-success mt-4" role="alert">
                                            Your application has been submitted successfully! We'll review it and get back to you soon.
                                        </div>
                                    )}
                                    {submitStatus.error && (
                                        <div className="alert alert-danger mt-4" role="alert">
                                            {submitStatus.error}
                                            {loadingState.error}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </main>
            <Footer />
            <Scrollbar />
        </SEOWrapper>
    );
};

export default InternshipPage;
