import React, { Fragment, useState } from 'react';
import Header2 from '../../components/Header2/Header2';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer';
import servicebg from '../../images/services/servicebg.webp'
import ModalVideo from 'react-modal-video'
import PolicySection from './Policy';
import WhyUs from './WhyUs';
import FeaturesSection from '../../components/FeaturesSection/FeaturesSection';
import PartnerSection from '../../components/PartnerSection/PartnerSection';
import SEOWrapper from '../../components/SEOWrapper/SEOWrapper';

const AboutUsPage = (props) => {

    const metaData = {
        title: "About Us | Invecent Private Limited",
        description: "Learn about Invecent Private Limited's journey, vision, and commitment to delivering innovative software solutions. Discover our values, mission, and expert team.",
        keywords: "about Invecent, company history, software company mission, IT services company, technology vision, company values, tech leadership",
        additionalTags: {
            'og:type': 'company',
            'article:section': 'About Us',
            'og:locale': 'en_US'
        }
    };

    const [isOpen, setOpen] = useState(false)

    return (
        <SEOWrapper metaData={metaData}>
            <Fragment>
                <Header2 />
                <main className="page_content about-page">
                    <PageTitle pageTitle={'About Us'} pagesub={'More Invecent'} pageTop={'About'}/>
                    <section className="intro_about_section section_space bg-light">
                        <div className="intro_about_content">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-10">
                                        <div className="image_wrap">
                                            <img src={servicebg} alt="Invecent - About " />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <div className="heading_block mb-0 section_space">
                                <div className="row justify-content-lg-between">
                                    <div className="col-lg-4">
                                        <h2 className="heading_text mb-0">
                                            We Provide You Flexible Services
                                        </h2>
                                    </div>
                                    <div className="col-lg-6">
                                        <p className="heading_description mb-0">
                                            At Invecent, we pride ourselves on offering dynamic and  IT  to suit your specific requirements. Our flexible approach means we can  adjust our offerings to accommodate your evolving needs, whether you require comprehensive IT infrastructure support, strategic consultancy, software development.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <PolicySection />
                    <PartnerSection />
                    <FeaturesSection />
                    <WhyUs />
                </main>
                <Footer />
                <Scrollbar />
                <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="7e90gBu4pas" onClose={() => setOpen(false)} />
            </Fragment>
        </SEOWrapper>
    )
};
export default AboutUsPage;
