import React from 'react';
import Slider from "react-slick";
import Bg from '../../images/icons/icon_quote_2.svg'
import Services from '../../api/service';


import { Link } from "react-router-dom";


const ServiceSlider = () => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    const settings = {
        dots: false,
        arrows: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const reverseSettings = {
        dots: false,
        arrows: false,
        speed: 600,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ],
        rtl: true // This setting makes the slider slide in reverse direction
    };

    const slider = React.useRef(null);



    return (
        <section className="review_and_about_section section_space bg-light">
            <div className="container">
                <div className="review_bg_box bg-success" style={{ backgroundImage: `url(${Bg})` }}>
                <div className="heading_block text-center">
                    <div className="heading_focus_text">
                        Our
                        <span className="badge bg-secondary text-white">Specialize</span>
                    </div>
                    <h2 className="heading_text mb-0">
                        Featured Services
                    </h2>
                </div>
                    <div className="review_4col_carousel swiper">
                        <Slider ref={slider} {...settings}>
                            {Services.slice(0,6).map((service, srv) => (
                                <div className={`${service.col} mt-30`} key={srv}>
                                {service.title ?
                                    <div className="service_block" style={{height: '500px',background: 'linear-gradient(136deg, #002e75, #0471a3)',borderRadius: 15}}>
                                        <div className="service_image">
                                            <img src={service.sImg} alt={service.title} />
                                        </div>
                                        <div className="service_content">
                                            <h3 className="service_title"><Link onClick={ClickHandler} to={`/service-single/${service.slug}`}>{service.title}</Link>
                                            </h3>
    
                                            <div className="links_wrapper">
                                                <ul className="category_btns_group unordered_list">
                                                    <li><Link onClick={ClickHandler} to={`/service-single/${service.slug}`}>{service.thumb1}</Link></li>
                                                    <li><Link onClick={ClickHandler} to={`/service-single/${service.slug}`}>{service.thumb2}</Link></li>
                                                </ul>
                                                <Link onClick={ClickHandler} to={`/service-single/${service.slug}`} className="icon_block">
                                                    <i className="fa-regular fa-arrow-up-right"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    : ''}
                            </div>
                            ))}
                        </Slider>
                    </div>

                    <div className="review_4col_carousel swiper">
                        <Slider ref={slider} {...reverseSettings}>
                            {Services.slice(6,13).map((service, srv) => (
                                <div className={`${service.col} mt-30`} key={srv}>
                                {service.title ?
                                    <div className="service_block" style={{height: '500px',background: 'linear-gradient(130deg, #002e75, #0471a3)',borderRadius: 15}}>
                                        <div className="service_image">
                                            <img src={service.sImg} alt={service.title} />
                                        </div>
                                        <div className="service_content">
                                            <h3 className="service_title"><Link onClick={ClickHandler} to={`/service-single/${service.slug}`}>{service.title}</Link>
                                            </h3>
    
                                            <div className="links_wrapper">
                                                <ul className="category_btns_group unordered_list">
                                                    <li><Link onClick={ClickHandler} to={`/service-single/${service.slug}`}>{service.thumb1}</Link></li>
                                                    <li><Link onClick={ClickHandler} to={`/service-single/${service.slug}`}>{service.thumb2}</Link></li>
                                                </ul>
                                                <Link onClick={ClickHandler} to={`/service-single/${service.slug}`} className="icon_block">
                                                    <i className="fa-regular fa-arrow-up-right"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    : ''}
                            </div>
                            ))}
                        </Slider>
                    </div>

                    <div className="review_4col_carousel swiper">
                        <Slider ref={slider} {...settings}>
                            {Services.slice(13,20).map((service, srv) => (
                                <div className={`${service.col} mt-30`} key={srv}>
                                {service.title ?
                                    <div className="service_block" style={{height: '500px',background: 'linear-gradient(126deg, #002e75, #0471a3)',borderRadius: 15}}>
                                        <div className="service_image">
                                            <img src={service.sImg} alt={service.title} />
                                        </div>
                                        <div className="service_content">
                                            <h3 className="service_title"><Link onClick={ClickHandler} to={`/service-single/${service.slug}`}>{service.title}</Link>
                                            </h3>
    
                                            <div className="links_wrapper">
                                                <ul className="category_btns_group unordered_list">
                                                    <li><Link onClick={ClickHandler} to={`/service-single/${service.slug}`}>{service.thumb1}</Link></li>
                                                    <li><Link onClick={ClickHandler} to={`/service-single/${service.slug}`}>{service.thumb2}</Link></li>
                                                </ul>
                                                <Link onClick={ClickHandler} to={`/service-single/${service.slug}`} className="icon_block">
                                                    <i className="fa-regular fa-arrow-up-right"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    : ''}
                            </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>
        </section>

        
    );
}

export default ServiceSlider;