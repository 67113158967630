import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import icon1 from '../../images/icons/icon_wifi.svg'
import icon2 from '../../images/icons/icon_dollar_2.svg'
import icon4 from '../../images/icons/icon_tag_2.svg'
import MobileMenu from '../MobileMenu/MobileMenu'
import invecentlogo from '../../images/site_logo/invecentlogo1.jpg'


const Header2 = (props) => {

    const [linkToNavigate, setLinkToNavigate] = useState('#process-technology');

  // Function to handle click and check the link availability
  const handleLinkClick = (event, primaryUrl, fallbackUrl) => {
    event.preventDefault();  // Prevent the default anchor behavior

    // Check the primary URL
    const checkLinkAvailability = async () => {
      try {
        const response = await fetch(primaryUrl, { method: 'HEAD' });

        // If the primary URL fails, set the fallback URL
        if (!response.ok) {
          setLinkToNavigate(fallbackUrl); // Set the fallback URL if primary fails
          window.location.href = fallbackUrl; // Redirect to the fallback URL
        } else {
          window.location.href = primaryUrl; // Proceed with the primary URL
        }
      } catch (error) {
        setLinkToNavigate(fallbackUrl); // Fallback to secondary URL on error
        window.location.href = fallbackUrl; // Redirect to the fallback URL
      }
    };

    // Call the function to check the link
    checkLinkAvailability();
    }

    const [mobailActive, setMobailState] = useState(false);

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    const [isSticky, setSticky] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 80) {
                setSticky(true);
            } else {
                setSticky(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Clean up
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (

        <header className="site_header site_header_2">
            <div className={`header_bottom stricky  ${isSticky ? 'stricked-menu stricky-fixed' : ''}`}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-2 col-lg-2 col-5">
                            <div className="site_logo">
                                <Link onClick={ClickHandler} className="site_link" to="/">
                                    <img src={invecentlogo} alt="Site Logo – Invecent – IT Solutions & Technology, Business Consulting, Software Company Site Template" />

                                    <img src={invecentlogo} alt="Site Logo – Invecent – IT Solutions & Technology, Business Consulting, Software Company Site Template" />
                                </Link>
                            </div>
                        </div>
                        <div className="col-xl-10 col-lg-10 col-2">
                            <nav className="main_menu navbar navbar-expand-lg">
                                <div className="main_menu_inner collapse navbar-collapse justify-content-lg-center" id="main_menu_dropdown">
                                    <ul className="main_menu_list unordered_list justify-content-center">
                                        <li><Link onClick={ClickHandler} to="/">Home</Link></li>

                                        <li className="dropdown">
                                            <Link onClick={ClickHandler} className="nav-link" to="/careers" id="portfolio_submenu" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                Company
                                            </Link>
                                            <ul className="dropdown-menu" aria-labelledby="portfolio_submenu">
                                                <li><Link onClick={ClickHandler} to="/itstaffing">IT Staffing</Link></li>
                                                <li><Link onClick={ClickHandler} to="/about">About Us</Link></li>
                                            </ul>
                                        </li>

                                        <li className="dropdown">
                                            <Link onClick={ClickHandler} className="nav-link" to="/service" id="company_submenu" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                Solutions
                                            </Link>
                                            <div className="dropdown-menu mega_menu_wrapper" aria-labelledby="company_submenu">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="megamenu_pages_wrapper mb-5">
                                                                <div className="row">
                                                                    <div className="col-lg-4 col-md-6">
                                                                        <Link onClick={ClickHandler} className="iconbox_block_2" to="/service">
                                                                            <span className="icon_title_wrap">
                                                                                <small className="iconbox_icon">
                                                                                    <img src={icon2} alt="Dollar SVG Icon" />
                                                                                </small>
                                                                                <small className="iconbox_title">Software Development</small>
                                                                            </span>
                                                                            <span className="description mb-0">
                                                                            <ul className="icon_list unordered_list_block">
                                                                            <li>
                                                                                <Link onClick={ClickHandler} to="/service-single/Web-Application-Development">
                                                                                    <span className="icon_list_text">
                                                                                        Web Development
                                                                                    </span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link onClick={ClickHandler} to="/service-single/Mobile-Application-Development">
                                                                                    <span className="icon_list_text">
                                                                                        App Development
                                                                                    </span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link onClick={ClickHandler} to="/service-single/Enterprise-Application">
                                                                                    <span className="icon_list_text">
                                                                                        Enterprise Application
                                                                                    </span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link onClick={ClickHandler} to="/service-single/UI-UX-Designing">
                                                                                    <span className="icon_list_text">
                                                                                        UI & UX Designing
                                                                                    </span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link onClick={ClickHandler} to="/service-single/Digital-Marketing">
                                                                                    <span className="icon_list_text">
                                                                                        Digital Marketing
                                                                                    </span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link onClick={ClickHandler} to="/service-single/DataScience-GenAI">
                                                                                    <span className="icon_list_text">
                                                                                        DataScience & GenAI
                                                                                    </span>
                                                                                </Link>
                                                                            </li>
                                                                            </ul>
                                                                            </span>
                                                                        </Link>
                                                                    </div>
                                                                    <div className="col-lg-4 col-md-6">
                                                                        <Link onClick={ClickHandler} className="iconbox_block_2" to="/service">
                                                                            <span className="icon_title_wrap">
                                                                                <small className="iconbox_icon">
                                                                                    <img src={icon1} alt="Wifi SVG Icon" />
                                                                                </small>
                                                                                <small className="iconbox_title">Ongoing Projects</small>
                                                                            </span>
                                                                            <ul className="icon_list unordered_list_block">
                                                                            <li>
                                                                                <Link onClick={ClickHandler} to="/service-single/Software-Management">
                                                                                    <span className="icon_list_text">
                                                                                        Software Management
                                                                                    </span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link onClick={ClickHandler} to="/service-single/Network-Infrastructure-Management">
                                                                                    <span className="icon_list_text">
                                                                                        Network Management
                                                                                    </span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link onClick={ClickHandler} to="/service-single/Computer-AMC-Service">
                                                                                    <span className="icon_list_text">
                                                                                        Computer AMC Service
                                                                                    </span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link onClick={ClickHandler} to="/service-single/Testing-Audit-Functions">
                                                                                    <span className="icon_list_text">
                                                                                        Software Testing
                                                                                    </span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link onClick={ClickHandler} to="/service-single/Cyber-Security">
                                                                                    <span className="icon_list_text">
                                                                                        Cyber Security Services
                                                                                    </span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link onClick={ClickHandler} to="/service-single/HRMS-Assessments">
                                                                                    <span className="icon_list_text">
                                                                                        HRMS Assessments
                                                                                    </span>
                                                                                </Link>
                                                                            </li>
                                                                            </ul>
                                                                        </Link>
                                                                    </div>
                                                                    <div className="col-lg-4 col-md-6">
                                                                        <Link onClick={ClickHandler} className="iconbox_block_2" to="/service">
                                                                            <span className="icon_title_wrap">
                                                                                <small className="iconbox_icon">
                                                                                    <img src={icon4} alt="Event Tag SVG Icon" />
                                                                                </small>
                                                                                <small className="iconbox_title">Managed Services</small>
                                                                            </span>
                                                                            <span className="description mb-0">
                                                                            <ul className="icon_list unordered_list_block">
                                                                            <li>
                                                                                <Link onClick={ClickHandler} to="/service-single/Migration-to-Cloud">
                                                                                    <span className="icon_list_text">
                                                                                        Migration to Cloud
                                                                                    </span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link onClick={ClickHandler} to="/service-single/Servers-Storages">
                                                                                    <span className="icon_list_text">
                                                                                    Server & Storages 
                                                                                    </span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link onClick={ClickHandler} to="/service-single/Desktop-Support">
                                                                                    <span className="icon_list_text">
                                                                                        Desktop Support
                                                                                    </span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link onClick={ClickHandler} to="/service-single/IT-Staffing">
                                                                                    <span className="icon_list_text">
                                                                                        IT Staffing
                                                                                    </span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link onClick={ClickHandler} to="/service-single/Data-Analytics">
                                                                                    <span className="icon_list_text">
                                                                                        Data Analytics
                                                                                    </span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link onClick={ClickHandler} to="/service-single/System-Integration">
                                                                                    <span className="icon_list_text">
                                                                                        System Integration
                                                                                    </span>
                                                                                </Link>
                                                                            </li>
                                                                            </ul>
                                                                            </span>
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <ul className="btns_group p-0 unordered_list" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '4px', padding: '2px', marginTop: '-4%', marginBottom: '1%' }}>
                                                                <li>
                                                                    <Link onClick={ClickHandler} className="btn btn-primary" to="/contact" style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <span className="btn_label" data-text="Reach Us">Reach Us</span>
                                                                    <span className="btn_icon" style={{ marginLeft: '8px' }}>
                                                                        <i className="fa-solid fa-arrow-up-right"></i>
                                                                    </span>
                                                                    </Link>
                                                                </li>
                                                                <br />
                                                                <li>
                                                                    <div className="social_area" style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                                                                    <p className="career_link m-0" style={{ fontSize: '14px' }}>
                                                                        <strong>         
                                                                            For new career? <Link onClick={ClickHandler} to="/careers" style={{ color: '#007bff', textDecoration: 'none' }}>We’re Hiring</Link>
                                                                        </strong>
                                                                    </p>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                
        
                                        <li className="dropdown">
                                            <Link onClick={ClickHandler} className="nav-link" to="/Technologies" id="pages_submenu" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                Technologies
                                            </Link>
                                            <ul className="dropdown-menu" aria-labelledby="pages_submenu">
                                                <li className="dropdown">
                                                    <Link onClick={ClickHandler} className="nav-link" to="/Technologies" id="blog_submenu" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        Web Technologies
                                                    </Link>
                                                    <ul className="dropdown-menu" aria-labelledby="blog_submenu">
                                                        <li><a href={linkToNavigate} onClick={(event) => handleLinkClick(event, '#process-technology', '/Technologies')} target="_blank" rel="noopener noreferrer">Angular</a></li>
                                                        <li><a href={linkToNavigate} onClick={(event) => handleLinkClick(event, '#process-technology', '/Technologies')} target="_blank" rel="noopener noreferrer">React</a></li>
                                                        <li><a href={linkToNavigate} onClick={(event) => handleLinkClick(event, '#process-technology', '/Technologies')} target="_blank" rel="noopener noreferrer">Next.js</a></li>
                                                        <li><a href={linkToNavigate} onClick={(event) => handleLinkClick(event, '#process-technology', '/Technologies')} target="_blank" rel="noopener noreferrer">Vue</a></li>
                                                        <li><a href={linkToNavigate} onClick={(event) => handleLinkClick(event, '#process-technology', '/Technologies')} target="_blank" rel="noopener noreferrer">HTML & CSS</a></li>
                                                    </ul>
                                                </li>
                                                <li className="dropdown">
                                                    <Link onClick={ClickHandler} className="nav-link" to="/Technologies" id="blog_submenu" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        Backend Technologies
                                                    </Link>
                                                    <ul className="dropdown-menu" aria-labelledby="blog_submenu">
                                                        <li><a href={linkToNavigate} onClick={(event) => handleLinkClick(event, '#process-technology', '/Technologies')} target="_blank" rel="noopener noreferrer">Java</a></li>
                                                        <li><a href={linkToNavigate} onClick={(event) => handleLinkClick(event, '#process-technology', '/Technologies')} target="_blank" rel="noopener noreferrer">Python</a></li>
                                                        <li><a href={linkToNavigate} onClick={(event) => handleLinkClick(event, '#process-technology', '/Technologies')} target="_blank" rel="noopener noreferrer">Express</a></li>
                                                        <li><a href={linkToNavigate} onClick={(event) => handleLinkClick(event, '#process-technology', '/Technologies')} target="_blank" rel="noopener noreferrer">Node.js</a></li>
                                                        <li><a href={linkToNavigate} onClick={(event) => handleLinkClick(event, '#process-technology', '/Technologies')} target="_blank" rel="noopener noreferrer">Ruby</a></li>
                                                        <li><a href={linkToNavigate} onClick={(event) => handleLinkClick(event, '#process-technology', '/Technologies')} target="_blank" rel="noopener noreferrer">PHP</a></li>
                                                    </ul>
                                                </li>
                                                <li className="dropdown">
                                                    <Link onClick={ClickHandler} className="nav-link" to="/Technologies" id="blog_submenu" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        Cloud Technologies
                                                    </Link>
                                                    <ul className="dropdown-menu" aria-labelledby="blog_submenu">
                                                        <li><a href={linkToNavigate} onClick={(event) => handleLinkClick(event, '#process-technology', '/Technologies')} target="_blank" rel="noopener noreferrer">AWS</a></li>
                                                        <li><a href={linkToNavigate} onClick={(event) => handleLinkClick(event, '#process-technology', '/Technologies')} target="_blank" rel="noopener noreferrer">GCP</a></li>
                                                        <li><a href={linkToNavigate} onClick={(event) => handleLinkClick(event, '#process-technology', '/Technologies')} target="_blank" rel="noopener noreferrer">Azure</a></li>
                                                        <li><a href={linkToNavigate} onClick={(event) => handleLinkClick(event, '#process-technology', '/Technologies')} target="_blank" rel="noopener noreferrer">Cloud Computing</a></li>
                                                    </ul>
                                                </li>
                                                <li className="dropdown">
                                                    <Link onClick={ClickHandler} className="nav-link" to="/Technologies" id="blog_submenu" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        Devops Technologies
                                                    </Link>
                                                    <ul className="dropdown-menu" aria-labelledby="blog_submenu">
                                                        <li><a href={linkToNavigate} onClick={(event) => handleLinkClick(event, '#process-technology', '/Technologies')} target="_blank" rel="noopener noreferrer">Docker</a></li>
                                                        <li><a href={linkToNavigate} onClick={(event) => handleLinkClick(event, '#process-technology', '/Technologies')} target="_blank" rel="noopener noreferrer">anasible</a></li>
                                                        <li><a href={linkToNavigate} onClick={(event) => handleLinkClick(event, '#process-technology', '/Technologies')} target="_blank" rel="noopener noreferrer">CICD</a></li>
                                                    </ul>
                                                </li>
                                                <li className="dropdown">
                                                    <Link onClick={ClickHandler} className="nav-link" to="/Technologies" id="blog_submenu" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        Mobile Technologies
                                                    </Link>
                                                    <ul className="dropdown-menu" aria-labelledby="blog_submenu">
                                                        <li><a href={linkToNavigate} onClick={(event) => handleLinkClick(event, '#process-technology', '/Technologies')} target="_blank" rel="noopener noreferrer">Swift</a></li>
                                                        <li><a href={linkToNavigate} onClick={(event) => handleLinkClick(event, '#process-technology', '/Technologies')} target="_blank" rel="noopener noreferrer">Java</a></li>
                                                        <li><a href={linkToNavigate} onClick={(event) => handleLinkClick(event, '#process-technology', '/Technologies')} target="_blank" rel="noopener noreferrer">React Native</a></li>
                                                        <li><a href={linkToNavigate} onClick={(event) => handleLinkClick(event, '#process-technology', '/Technologies')} target="_blank" rel="noopener noreferrer">Flutter</a></li>
                                                        <li><a href={linkToNavigate} onClick={(event) => handleLinkClick(event, '#process-technology', '/Technologies')} target="_blank" rel="noopener noreferrer">Laraval</a></li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                
        
                                        <li className="dropdown">
                                            <Link onClick={ClickHandler} className="nav-link" to="/careers" id="portfolio_submenu" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                Collaboration
                                            </Link>
                                            <ul className="dropdown-menu" aria-labelledby="portfolio_submenu">
                                                <li><Link onClick={ClickHandler} to="/collaborations/Universities">Universities</Link></li>
                                                <li><Link onClick={ClickHandler} to="/collaborations/Franchise">Franchies</Link></li>
                                                <li><Link onClick={ClickHandler} to="/collaborations/Companies">Companies</Link></li>
                                                <li><Link onClick={ClickHandler} to="/collaborations/StartUps">StartUps</Link></li>
                                                <li><Link onClick={ClickHandler} to="/collaborations/Investors">Investors</Link></li>
                                            </ul>
                                        </li>
                                        <li className="dropdown">
                                            <Link onClick={ClickHandler} className="nav-link" to="/careers" id="portfolio_submenu" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                Careers
                                            </Link>
                                            <ul className="dropdown-menu" aria-labelledby="portfolio_submenu">
                                                <li><Link onClick={ClickHandler} to="/ITForm">IT Jobs</Link></li>
                                                <li><Link onClick={ClickHandler} to="/NonITForm">Non IT Jobs</Link></li>
                                                <li><Link onClick={ClickHandler} to="/internship">Internship</Link></li>
                                            </ul>
                                        </li>
                                        <li><Link onClick={ClickHandler} to="/CSR">CSR</Link></li>
                                        <li><Link onClick={ClickHandler} to="/contact">Contact</Link></li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                        <div className="col-xl-1 col-lg-1 col-5">
                            <ul className="header_btns_group unordered_list justify-content-end">
                                <li>
                                    <button className="mobile_menu_btn" onClick={() => setMobailState(!mobailActive)} type="button" data-bs-toggle="collapse" data-bs-target="#main_menu_dropdown" aria-expanded="false" aria-label="Toggle navigation">
                                        <i className="far fa-bars"></i>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="mobail-wrap">
                    <div className={`mobail-menu ${mobailActive ? "active" : ""}`}>
                        <div className="xb-header-menu-scroll">
                            <div className="xb-menu-close xb-hide-xl xb-close" onClick={() => setMobailState(!mobailActive)}></div>
                            <nav className="xb-header-nav">
                                <MobileMenu />
                            </nav>
                        </div>
                    </div>
                    <div className="xb-header-menu-backdrop" onClick={() => setMobailState(false)}></div>
                </div>
            </div>
        </header>

    )
}

export default Header2;