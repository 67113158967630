import React, { Fragment, useState } from 'react';
import SEOWrapper from '../../components/SEOWrapper/SEOWrapper';
import Services from '../../api/service'
import { useParams } from 'react-router-dom'
import ModalVideo from 'react-modal-video'
import Header2 from '../../components/Header2/Header2';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer';
import ProcessTechnology from '../../components/software-company-components/ProcessTechnology/ProcessTechnology';
import ContactFormNew from '../../components/contactFormNew/contactFormNew';
import ArifEffect from '../Animations/ArifEffect';
import CardEffect from '../Animations/CardEffect';

const ServiceSinglePage = () => {

    const { slug } = useParams()

    const ServiceDetails = Services.find(item => item.slug === slug)

    const metaData = {
        title: `${ServiceDetails.title} Services | Invecent Private Limited`,
        description: `Discover our comprehensive ${ServiceDetails.title} services and solutions at Invecent Private Limited. From software development to cloud solutions, we deliver innovative technology solutions tailored to your business needs.`,
        keywords: `IT services, ${ServiceDetails.title}, software solutions, technology consulting, digital transformation, cloud services, enterprise solutions, custom software, IT infrastructure`,
        additionalTags: {
            'og:type': 'website',
            'article:section': `${ServiceDetails.title} Services`,
            'og:locale': 'en_US'
        }
    };

    const [isOpen, setOpen] = useState(false)

    return (
        <SEOWrapper metaData={metaData}>
            <Fragment>
                <Header2 />
                <main className="page_content service-single-page">
                    <PageTitle pageTitle={ServiceDetails.title} pagesub={'Details'} pageTop={'Services'} pageBg={ServiceDetails.bgImg}/>
                    <section className="service_details_section section_space bg-light">
                        <div className="container">
                            <div className="row">
                            <h2 className="details_item_title">{ServiceDetails.title}</h2>
                            
                            <div className='col-lg-7 mb-4'>
                            <ArifEffect variants={{ hidden: { opacity: 0, x: -100 }, peek: { opacity: 1, x: 0 } }}>
                                <p>
                                    {ServiceDetails.description}
                                </p>
                                <p>
                                At Invecent, {ServiceDetails.description2}
                                </p>

                                </ArifEffect>
                            </div>
                            <div className='col-lg-5'>
                            <ArifEffect variants={{ hidden: { opacity: 0, x: 100 }, peek: { opacity: 1, x: 0 } }}>
                            <div className="details_item_image position-relative">
                                <a href={'https://www.invecent.com/service-single/'+ServiceDetails.slug} target='_blank' rel='noopener noreferrer'>
                                    <img src={ServiceDetails.sImg} alt={ServiceDetails.title} />
                                </a>
                            </div>
                            </ArifEffect>
                            </div>
                            </div>
                           
                            <div className="row section_space">
                            <div className='col-lg-5'>
                            <ArifEffect variants={{ hidden: { opacity: 0, x: -100 }, peek: { opacity: 1, x: 0 } }}>
                            <div className="details_item_image position-relative">
                                <a href={'https://www.invecent.com/service-single/'+ServiceDetails.slug} target='_blank' rel='noopener noreferrer'>
                                    <img src={ServiceDetails.subImg1} alt={ServiceDetails.title} />
                                </a>
                            </div>
                            </ArifEffect>
                            </div>

                            <div className='col-lg-7 mb-4'>  
                            <ArifEffect variants={{ hidden: { opacity: 0, x: 100 }, peek: { opacity: 1, x: 0 } }}>         
                                <p className="details_item_info_title">{ServiceDetails.description3}</p>
                                <p>
                                    We take a holistic approach to network design, considering factors such as bandwidth requirements, security protocols, scalability, and future growth potential. By leveraging industry best practices and cutting-edge technologies,
                                </p>
                            </ArifEffect>
                            </div>
                            </div>

                            <div className="row mb-4">
                                <h2 className='text-center'>{ServiceDetails.thumb1} Services We Offer</h2>
                                <div className="col-lg-12">
                                    <div className="row g-4 features_list">
                                    {ServiceDetails.features.slice(0,6).map((feature, featureIndex) => (
                                        <div key={featureIndex} className="col-md-6 col-xl-4">
                                            <CardEffect>
                                            <div 
                                                className="card feature-3d-card" 
                                                style={{
                                                    transform: 'perspective(1000px)',
                                                    transformStyle: 'preserve-3d',
                                                    transition: 'all 0.3s ease',
                                                    boxShadow: '0 10px 20px rgba(0,0,0,0.12)',
                                                    borderRadius: '16px',
                                                    background: 'linear-gradient(145deg, #f0f5fc, #ffffff)',
                                                    padding: '10px',
                                                    textAlign: 'center',
                                                    position: 'relative',
                                                    overflow: 'hidden',
                                                }}
                                                  onMouseEnter={(e) => {
                                                      const card = e.currentTarget;
                                                      card.style.transition = 'all 0.3s ease';
                                                      card.style.transform = `
                                                          perspective(1000px) 
                                                          rotateX(0deg) 
                                                          rotateY(0deg) 
                                                          translateZ(45px)
                                                          scale(1.04)
                                                      `;
                                                      card.style.boxShadow = '0 25px 50px rgba(0,0,0,0.2)';
                                                  }}
                                                  onMouseLeave={(e) => {
                                                      const card = e.currentTarget;
                                                      card.style.transition = 'all 0.3s ease';
                                                      card.style.transform = `
                                                          perspective(1000px) 
                                                          rotateX(0deg) 
                                                          rotateY(0deg) 
                                                          translateZ(0px)
                                                          scale(1)
                                                      `;
                                                      card.style.boxShadow = '0 10px 20px rgba(0,0,0,0.12)';
                                                  }}
                                            >
                                                    <span 
                                                        className="icon_list_text" 
                                                        style={{
                                                            fontWeight: 'bold',
                                                            color: 'var(--bs-dark)',
                                                            textAlign: 'center'
                                                        }}
                                                    >
                                                        {feature}
                                                    </span>
                                                <div 
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        height: '320px'
                                                    }}
                                                >
                                                        <img 
                                                            src={ServiceDetails.featuresImage[featureIndex]} 
                                                            alt={ServiceDetails.features[featureIndex]} 
                                                            className='radious-50 border-radius-50'
                                                            style={{
                                                                width: '100%',
                                                                height: '210px',
                                                                objectFit: 'cover',
                                                                borderRadius: '16%'
                                                            }}
                                                        />
                                                    <a href={'https://www.invecent.com/contact'} target='_blank' rel='noopener noreferrer'>
                                                    <button className="btn btn-primary mt-3 px-4 py-2">Get Quote</button>
                                                    </a>
                                                </div>
                                            </div>
                                            </CardEffect>
                                        </div>
                                    ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ProcessTechnology />
                        <ContactFormNew />
                    </section>
                </main>
                <Footer />
                <Scrollbar />
                <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="7e90gBu4pas" onClose={() => setOpen(false)} />
            </Fragment>
        </SEOWrapper>
    )
};
export default ServiceSinglePage;