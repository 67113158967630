import React, { Fragment } from 'react';
import SEOWrapper from '../../components/SEOWrapper/SEOWrapper';
import Header2 from '../../components/Header2/Header2';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer';
import ContactFormNew from '../../components/contactFormNew/contactFormNew';

// Import icons
import EducationIcon from '../../images/icons/education.webp';
import EnvironmentIcon from '../../images/icons/Environment.webp';
import CommunityIcon from '../../images/icons/comuni.jpg';
import HealthIcon from '../../images/icons/health.jpg';
import Environment from '../../images/icons/environ.webp'
import ArifEffect from '../Animations/ArifEffect';

const CSRPage = () => {
    const metaData = {
        title: "Corporate Social Responsibility | Invecent Private Limited",
        description: "Discover Invecent's holistic approach to Corporate Social Responsibility. We are committed to sustainable development, community empowerment, and creating positive social impact.",
        keywords: "CSR, corporate social responsibility, sustainability, community development, social impact, environmental initiatives, corporate citizenship, social responsibility",
        additionalTags: {
            'og:type': 'website',
            'article:section': 'CSR',
            'og:locale': 'en_US'
        }
    };

    const csrInitiatives = [
        {
            id: 'education',
            icon: EducationIcon,
            title: 'Education Empowerment',
            description: 'We believe in the power of education to transform lives. Our CSR initiatives focus on providing educational resources, literacy programs to underprivileged.',
            highlights: [
                'Digital Skills Training',
                'Scholarship Programs',
                'STEM Education Support',
                'Educational Development'
            ]
        },
        {
            id: 'environment',
            icon: Environment,
            title: 'Environmental Sustainability',
            description: 'Committed to protecting our planet, we implement green technologies, reduce carbon footprint, and support environmental conservation.',
            highlights: [
                'Carbon Neutral Initiatives',
                'Renewable Energy Adoption',
                'E-Waste Management',
                'Tree Plantation Drives'
            ]
        },
        {
            id: 'community',
            icon: CommunityIcon,
            title: 'Community Development',
            description: 'We strive to create meaningful impact by supporting local communities through skill development, healthcare, and empowerment programs.',
            highlights: [
                'Skill Development Workshops',
                'Women Empowerment',
                'Rural Digital Transformation',
                'Healthcare Programs'
            ]
        },
        {
            id: 'health',
            icon: HealthIcon,
            title: 'Health and Wellness',
            description: 'Promoting health and well-being is at the core of our social responsibility. We support healthcare initiatives and mental wellness programs.',
            highlights: [
                'Healthcare Access Programs',
                'Mental Health Awareness',
                'COVID-19 Support Initiatives',
                'Wellness Workshops'
            ]
        }
    ];

    return (
        <SEOWrapper metaData={metaData}>
            <Fragment>
                <Header2 />
                <main className="page_content">
                    <PageTitle 
                        pageTitle={'Corporate Social Responsibility'} 
                        pagesub={'Our Commitment'} 
                        pageTop={'CSR Initiatives'}
                    />
                    
                    <section className="csr_overview section_space bg-light">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <ArifEffect variants={{ hidden: { opacity: 0, x: -100 }, peek: { opacity: 1, x: 0 } }}>
                                    <div className="section_heading mb-4">
                                        <h2 className="heading_text">
                                            Driving <mark>Positive Change</mark> Through Responsible Business
                                        </h2>
                                        <p className="heading_description">
                                            At Invecent, we believe that our success is intrinsically linked to the well-being of our communities and the planet. Our Corporate Social Responsibility (CSR) initiatives are designed to create sustainable, meaningful impact across multiple dimensions of social development.
                                        </p>
                                    </div>
                                    </ArifEffect>
                                </div>
                                <div className="col-lg-6">
                                    <ArifEffect variants={{ hidden: { opacity: 0, x: 100 }, peek: { opacity: 1, x: 0 } }}>
                                    <div className="csr_hero_image">
                                        <img 
                                            src={EnvironmentIcon} 
                                            alt="Invecent CSR Initiatives" 
                                            className="img-fluid rounded shadow-lg"
                                        />
                                    </div>
                                    </ArifEffect>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="csr_initiatives section_space">
                        <div className="container">
                            <div className="section_heading text-center mb-5">
                                <h2 className="heading_text">
                                    Our <mark>Key Focus Areas</mark>
                                </h2>
                                <p className="heading_description">
                                    We are committed to making a difference through targeted and impactful CSR programs.
                                </p>
                            </div>
                            
                            <div className="row">
                                {csrInitiatives.map((initiative) => (
                                    <div key={initiative.id} className="col-xl-3 col-md-6 px-5">
                                        <ArifEffect>
                                        <div className="csr_initiative_card text-center justify-content-center p-4 rounded shadow-sm">
                                            <div className="initiative_icon mb-3">
                                                <a href='https://www.invecent.com' target='_blank' rel='noreferrer'><img src={initiative.icon} alt={initiative.title} /></a>
                                            </div>
                                            <h3 className="initiative_title mb-3">{initiative.title}</h3>
                                            <p className="initiative_description mb-3">{initiative.description}</p>
                                            <ul className="initiative_highlights list-unstyled text-center">
                                                {initiative.highlights.map((highlight, index) => (
                                                    <li key={index} className="mb-2 d-flex justify-content-start">
                                                        <i className="fa fa-check-circle text-success me-2 "></i>
                                                        {highlight}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                        </ArifEffect>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>

                    <section className="csr_impact section_space bg-light">
                        <div className="container">
                            <div className="section_heading text-center mb-5">
                                <h2 className="heading_text">
                                    Measuring Our <mark>Social Impact</mark>
                                </h2>
                                <p className="heading_description">
                                    We are committed to transparency and continuous improvement in our CSR efforts.
                                </p>
                            </div>
                            <div className="row text-center">
                                <div className="col-lg-3 col-md-6 mb-4">
                                    <div className="impact_metric p-4 rounded shadow-sm">
                                        <h3 className="display-4 text-primary mb-2">500+</h3>
                                        <p className="text-muted">Students Trained</p>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 mb-4">
                                    <div className="impact_metric p-4 rounded shadow-sm">
                                        <h3 className="display-4 text-success mb-2">1000+</h3>
                                        <p className="text-muted">Community Members Supported</p>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 mb-4">
                                    <div className="impact_metric p-4 rounded shadow-sm">
                                        <h3 className="display-4 text-info mb-2">50+</h3>
                                        <p className="text-muted">Environmental Projects</p>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 mb-4">
                                    <div className="impact_metric p-4 rounded shadow-sm">
                                        <h3 className="display-4 text-warning mb-2">10+</h3>
                                        <p className="text-muted">Healthcare Initiatives</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <ContactFormNew />
                </main>
                <Footer />
                <Scrollbar />
            </Fragment>
        </SEOWrapper>
    )
};

export default CSRPage;