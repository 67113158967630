import React from 'react'
import ContactFormNew from '../contactFormNew/contactFormNew'
import icon1 from '../../images/icons/icon_map_mark_2.svg'
import icon3 from '../../images/icons/icon_mail_3.svg'
import icon4 from '../../images/icons/icon_calendar_2.svg'
import Loactions from '../../main-component/Locations/Locations'
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Locations data
const locations = [
  { id: 1, name: 'Hitech City', position: [17.434591700365058, 78.378658326318] },
  { id: 2, name: 'London', position: [51.51, -0.1] },
  { id: 3, name: 'UAE', position: [25.25123479580366, 55.29604180427936] },
  { id: 4, name: 'Portugal', position: [41.1446300737565, -8.606992039246764] },
];



const ContactSection = (props) => {
    const SetBounds = () => {
        const map = useMap();
    
        // Extract all marker positions
        const bounds = L.latLngBounds(locations.map(location => location.position));
        map.fitBounds(bounds, { padding: [50, 50] }); // Adjust padding for better view
    
        return null;
      };
      
     return (
        <section className="contact_section section_space bg-light">
            <div className="container">
                <div className="contact_info_box row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="iconbox_block text-center">
                            <div className="iconbox_icon">
                                <img src={icon1} alt="Map Mark SVG Icon" />
                            </div>
                            <div className="iconbox_content">
                                <h3 className="iconbox_title">Location</h3>
                                <p className="mb-0">
                                    T-Hub, Raidurgam Hyderabad Knowledge City, Telangana.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="iconbox_block text-center">
                            <div className="iconbox_icon">
                                <img src={icon3} alt="User Check SVG Icon" />
                            </div>
                            <div className="iconbox_content">
                                <h3 className="iconbox_title">Email</h3>
                                <p className="mb-0">admin@invecent.com</p>
                                <p className="mb-0">hr@invecent.com</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="iconbox_block text-center">
                            <div className="iconbox_icon">
                                <img src={icon4} alt="Calendar SVG Icon" />
                            </div>
                            <div className="iconbox_content">
                                <h3 className="iconbox_title">Visit Between</h3>
                                <p className="mb-0">Mon - Sat: 10.00-6.00</p>
                                <p className="mb-0">Sunday: Closed</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section_space pb-0">

                    <div style={{ height: '500px', width: '100%' }}>
                    <MapContainer center={[51.505, -0.09]} zoom={13} style={{ height: '100%', width: '100%' }}>
                        <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />

                        <SetBounds />

                        {/* Add markers with bounce effect */}
                        {locations.map(location => (
                        <Marker
                            key={location.id}
                            position={location.position}
                            icon={L.icon({
                            iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
                            iconSize: [25, 41],
                            iconAnchor: [12, 41],
                            popupAnchor: [1, -34],
                            shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
                            })}
                            className="Marker fa-bounce"
                            style={{ animation: 'fa-bounce 1s infinite' }}
                        >
                            <Popup  style={{ animation: 'fa-bounce 1s infinite' }} >{location.name}</Popup>
                        </Marker>
                        ))}
                    </MapContainer>
                    </div>
                </div>
            </div>
        <Loactions />
        <div className="contact_form mb-0 text-center section_space">
            <h3 className="details_item_info_title mb-1">Send Us A Message</h3>
                <p className="mb-5">
                    Give us chance to serve and bring magic to your brand.
                </p>
            <ContactFormNew />
        </div>
        </section>

    )
}

export default ContactSection;