import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import './style.css'


const Scrollbar = () => {

    return (
        <ul className="smothscroll">
            <div className="icon-bar">
            <a href="/" aria-label="facebook" style={{backgroundColor: '#3b5998',color: 'white'}}><i className="fa-brands fa-facebook fa-bounce"></i></a> 
            <a href="/" aria-label="twitter" style={{backgroundColor: '#55ACEE',color: 'white'}}><i className="fa-brands fa-twitter fa-bounce"></i></a> 
            <a href="/" aria-label="google" style={{backgroundColor: '#dd4b39',color: 'white'}}><i className="fa-brands fa-google fa-bounce"></i></a> 
            <a href="https://www.linkedin.com/company/invecent-private-limited/" target="_blank" rel="noreferrer" aria-label="linkedin" style={{backgroundColor: '#007bb5',color: 'white'}}><i className="fa-brands fa-linkedin fa-bounce"></i></a>
            <a href="/" aria-label="youtube" style={{backgroundColor: '#bb0000',color: 'white'}}><i className="fa-brands fa-youtube fa-bounce"></i></a> 
            <a href="/" aria-label="whatsapp" style={{backgroundColor: '#25d366',color: 'white'}}><i className="fa-brands fa-whatsapp fa-bounce"></i></a> 
            </div>
            <li><AnchorLink href='#scrool' aria-label='Scroll to top'><i className="ti-arrow-up fa-bounce"></i></AnchorLink></li>
        </ul>
    )
}

export default Scrollbar;
