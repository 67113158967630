import React from 'react'

const PageTitle = (props) => {
    return (
        <section className="page_banner_section text-center" 
            style={{ 
                backgroundImage: `url(${props.pageBg})`, 
                backgroundSize: 'cover', 
                backgroundRepeat: 'no-repeat', 
                backgroundPosition: 'center',
                position: 'relative' // Important to position the overlay
            }}>
            {/* Overlay to enhance text visibility */}
            <div 
                style={{
                    position: 'absolute', 
                    top: 0, 
                    left: 0, 
                    right: 0, 
                    bottom: 0, 
                    backgroundColor: '#1b2155b5', // Black overlay with 50% opacity
                    zIndex: 1 // Ensure the overlay is behind the text
                }}
            ></div>

            <div className="container" style={{ position: 'relative', zIndex: 2 }}>
                <div className="heading_focus_text text-white">
                    {props.pageTop}
                    <span className="badge bg-secondary">{props.pagesub}</span>
                </div>
                <h1 className="page_title mb-0 text-white">{props.pageTitle}</h1>
            </div>
        </section>       
    )
}

export default PageTitle;