import timg4 from '../images/team/team_member_image_4.webp'



const Teams = [
   {
      Id: '1',
      tImg: timg4,
      name: 'Arif Shaik',
      slug: 'Arif',
      title: 'Software Developer',
   },
   
]

export default Teams;