import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const places = [
    {
      id: '01',
      tImg: 'https://images.pexels.com/photos/1603650/pexels-photo-1603650.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
      Title: 'India Office',
      Des: 'T-Hub, Raidurgam, knowledge city, Hyderabad',
      Email: 'hr@invecent.com',
    },
    {
      id: '02',
      tImg: 'https://imgs.search.brave.com/G-pOVc6Ule1Y-OdxvOXv3CiA_WirZPJdHIyCfpzJT5g/rs:fit:500:0:0:0/g:ce/aHR0cHM6Ly90NC5m/dGNkbi5uZXQvanBn/LzA3LzgwLzg5LzI3/LzM2MF9GXzc4MDg5/Mjc4M19BSlREUENC/SURUbVNITWtJSjNV/SGdGSDNTV2FsNVlt/Zy5qcGc',
      Title: 'Portugal Office',
      Des: 'Ribeira, Portugal',
      Email: 'info@invecent.com',
    },
    {
      id: '03',
      tImg: 'https://images.pexels.com/photos/51363/london-tower-bridge-bridge-monument-51363.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
      Title: 'UK Office',
      Des: 'Luton, UK',
      Email: 'admin@invecent.com',
    },
    {
      id: '04',
      tImg: 'https://images.pexels.com/photos/3214995/pexels-photo-3214995.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
      Title: 'UAE Office',
      Des: 'AL Mankhool, UAE',
      Email: 'info@invecent.com',
    },
];

const Loactions = () => {
const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
        {
            breakpoint: 1025,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                arrows: false
            }
        },
        {
            breakpoint: 769,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                arrows: false
            }
        },
        {
            breakpoint: 450,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false
            }
        },
        {
            breakpoint: 350,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false
            }
        }
    ]
};

return (
    <section className="review_section section_space bg-light" >
        <div className="container">
            <div className="heading_block text-center">
                {/* <div className="heading_focus_text">
                    <span className="badge bg-secondary text-white">Client</span>
                    Testimonials
                </div> */}
                <h2 className="heading_text mb-0">
                    Invecent Branches
                </h2>
            </div>

            <Slider {...settings}>
                {places.map((testimonial, tsm) => (
                    <div key={tsm} className="px-5">
                        <div 
                            className="review_block bg-white p-4 rounded shadow-sm" 
                            style={{
                                height: '267px', 
                                display: 'flex', 
                                flexDirection: 'column', 
                                justifyContent: 'space-between'
                            }}
                        >
                            <div>
                                <h4 className="review_title mb-2">{testimonial.Title}</h4>
                                <p className="review_comment text-muted">
                                    {testimonial.Des}
                                </p>
                                <p className="review_comment" style={{color: 'blue'}}>
                                    {testimonial.Email}
                                </p>
                            </div>
                                <div className="review_admin_image">
                                    <img 
                                        src={testimonial.tImg} 
                                        alt={testimonial.Name} 
                                        className="rounded-circle" 
                                        style={{width: '65px', height: '65px', objectFit: 'cover',marginTop: '-10px'}} 
                                    />
                                </div>
                            {/* <div className="review_admin d-flex align-items-center">
                                <div className="review_admin_image">
                                    <img 
                                        src={testimonial.tImg} 
                                        alt={testimonial.Name} 
                                        className="rounded-circle" 
                                        style={{width: '50px', height: '50px', objectFit: 'cover',marginTop: '-10px'}} 
                                    />
                                </div>
                                <div className="review_admin_info">
                                    <h4 className="review_admin_name mb-1">{testimonial.Name}</h4>
                                    <span className="review_admin_designation text-muted">
                                        {testimonial.sub}
                                    </span>
                                    <div>
                                    {[...Array(testimonial.rating)].map((_, index) => (
                                        <span 
                                        key={index} 
                                        style={{
                                            color: index < testimonial.rating ? '#ffc107' : '#e4e5e9',
                                            fontSize: '1.5rem',
                                            marginRight: '3px'
                                        }}
                                        >
                                        ★
                                        </span>
                                    ))}
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    </section>
);
}

export default Loactions;